import { useAutoAnimate } from '@formkit/auto-animate/react'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import { useOrganization } from '../../hooks'

import { ListItem } from './ListItem'

import type { Conversation } from '@tivio/types'


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

interface Props {
    conversations: Conversation[]
    activeConversationId?: string
}

export const ConversationList = observer(({ activeConversationId, conversations }: Props) => {
    const [ref] = useAutoAnimate({
        duration: 500,
    })
    const { organization } = useOrganization()
    const userPath = organization?.path
    const getConversationTitle = (conversation: Conversation) =>
        conversation.participants
            .reduce<{ name: string; path: string, email: string }[]>((acc, participant) => {
                if (participant.path !== userPath) {
                    acc.push({ name: participant.name, path: participant.path, email: participant.email })
                }
                return acc
            }, [])
            .sort((a) => (a.path === conversation.lastMessage?.participant.path ? -1 : 1))
            .map((participant) => ({ name: participant.name, email: participant.email }))[0]

    return (
        <Container ref={ref}>
            {conversations.map((item) => item.lastMessage && (
                <ListItem
                    key={item.id}
                    id={item.id}
                    date={item.updatedAt}
                    avatarUrl={item.participants.find((participant) => participant.path !== userPath)?.avatarUrl}
                    isUnread={item.notSeenCount > 0}
                    message={item.lastMessage?.text ?? ''}
                    name={getConversationTitle(item).name}
                    isActive={activeConversationId === item.id}
                    isLastMessageFromCurrentUser={item.lastMessage?.participant.path === userPath}
                    email={getConversationTitle(item).email}
                />
            ))}
        </Container>
    )
})
