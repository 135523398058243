import { DocumentReference, TaskDocument, TaskPayloadMap, TaskStatus, TaskType, Timestamp, TaskQueue } from '@tivio/firebase'
import { FirebaseDocumentData, FirestoreDocumentSnapshot, FirestoreTimestamp, getCurrentFirebaseTimestamp, getFirestore, loggerFirestore } from '../app'
import { Task } from '../../store/Task'


export type ScheduleTaskData<T extends TaskType> = {
    type: T
    payload: TaskPayloadMap[T]
    scheduledTime: Timestamp
    queue: TaskQueue
}

export const getTasksCollection = <T extends TaskType>() => getFirestore()
    .collection('tasks')
    .withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): TaskDocument<T> => {
            return snapshot.data() as TaskDocument<T>
        },
        toFirestore: (taskFirestore: TaskDocument<T>): FirebaseDocumentData => {
            return taskFirestore
        },
    })


export const subscribeToTasksByContentRef = <T extends TaskType>(
    contentRef: DocumentReference, 
    callback: (tasks: Task[]) => void
): () => void => {
    return getFirestore()
    .collection('tasks')
    .where('contentRef', '==', contentRef)
    .onSnapshot(snapshot => {
        const tasks = snapshot.docs.map((doc) => new Task(doc.ref as DocumentReference<TaskDocument<T>>, doc.data() as TaskDocument<T>))
        callback(tasks)
    })
}


export const scheduleTask = async <T extends TaskType>({
    type,
    payload,
    scheduledTime,
    queue
}: ScheduleTaskData<T>): Promise<string> => {

    let contentRef: DocumentReference | undefined;
    if ('collection' in payload && 'id' in payload) {
        contentRef = getFirestore().collection(payload.collection).doc(payload.id);
    }

    try {
        const taskData: TaskDocument<TaskType> = {
            type,
            payload,
            status: TaskStatus.NEW,
            scheduledTime,
            created: getCurrentFirebaseTimestamp(),
            queue,
            attemptCount: 0,
            ...(contentRef && {
                contentRef: contentRef
            }),
        }
        const docRef = await getTasksCollection().add(taskData)
        loggerFirestore.info('Task was created', taskData)
        return docRef.id
    } catch (e) {
        loggerFirestore.error('Failed to create task. Error:', e)
        throw new Error(e)
    }
}

export const reScheduleTask = async (taskId: string, newScheduledTime: FirestoreTimestamp) => {
    const task = await getTasksCollection().doc(taskId).get()
    if (!task.exists) {
        throw new Error('Task not found')
    }
    await task.ref.update({
        scheduledTime: newScheduledTime,
    })
}

export const cancelTask = async (taskId: string) => {
    const task = await getTasksCollection().doc(taskId).get()
    if (!task.exists) {
        throw new Error('Task not found')
    }
    await task.ref.update({
        status: TaskStatus.CANCELLED,
    })
}