import React from 'react'
import { useParams } from 'react-router'

import { ConversationPage } from './ConversationPage'

import type { UserLayoutParams } from '../router/layout/UserLayout'


export const CommunityPage = () => {
    const { conversationId } = useParams<UserLayoutParams>()

    return (
        <div>
            <ConversationPage conversationId={conversationId} />
        </div>
    )
}
