import { Logger, User } from '@tivio/core-js'
import { useState } from 'react'


export interface UseConversationsOptions {
    conversationId?: string;
}

const logger = new Logger('useConversations')

export const useConversations = ({ conversationId }: UseConversationsOptions = {}) => {
    const [isLoading, setIsLoading] = useState(false)
    const createConversation = async (participantPaths: string[]) => {
        setIsLoading(true)
        try {
            const res = await User.currentUser?.conversationStore.createConversation(participantPaths)
            if (!res) {
                logger.error('Failed to create conversation')
                return
            }
            return res.id
        } catch (error) {
            logger.error('Failed to create conversation', error)
        } finally {
            setIsLoading(false)
        }
    }

    return {
        conversations: User.currentUser?.conversationStore.allConversations ?? [],
        createConversation,
        isLoading,
    }
}
