import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

import Logger from '../logger'

import dayjs, { Dayjs } from 'dayjs'


type FirestoreCollectionReference<T = FirebaseDocumentData> = firebase.firestore.CollectionReference<T>
type FirebaseDocumentData = firebase.firestore.DocumentData
type FirestoreDocumentSnapshot<T = FirebaseDocumentData> = firebase.firestore.DocumentSnapshot<T>
type FirestoreDocumentReference<T = FirebaseDocumentData> = firebase.firestore.DocumentReference<T>
type FirestoreQueryDocumentSnapshot<T = FirebaseDocumentData> = firebase.firestore.QueryDocumentSnapshot<T>
type FirestoreQuery<T = FirebaseDocumentData> = firebase.firestore.Query<T>
type FirebaseUser = firebase.User
type FirestoreTimestamp = firebase.firestore.Timestamp

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_FIREBASE_API_KEY: string
            REACT_APP_FIREBASE_RESET_PASSWORD_CONTINUE_URL: string
            REACT_APP_FIREBASE_AUTH_DOMAIN: string
            REACT_APP_FIREBASE_PROJECT_ID: string
            REACT_APP_FIREBASE_STORAGE_BUCKET: string
            REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string
            REACT_APP_FIREBASE_APP_ID: string
            REACT_APP_FIREBASE_MEASUREMENT_ID: string
            REACT_APP_PATREON_CLIENT_ID: string
            REACT_APP_PATREON_ENDPOINT: string
            REACT_APP_GLOBAL_ACCESS_TAGS_ORG_ID: string
            REACT_APP_ACCESS_TAG_TYPE_ID: string
            REACT_APP_INDICATION_TAG_TYPE_ID: string
            REACT_APP_DVTV_EXTRA_TAG_ID: string
            REACT_APP_MAGENTA_TV_TAG_ID: string
            REACT_APP_PRODUCT_PLACEMENT_GLOBAL_TAG_ID: string
            REACT_APP_EPG_VIDEOS_ORG_ID: string
        }
    }
}

const firebaseApp = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

// In need of emulator use
// firebaseApp.functions().useEmulator('localhost', 5001)
// firebaseApp.storage().useEmulator('localhost', 9199)

const getFirestore = () => firebaseApp.firestore()
const getStorage = (bucketUri?: BucketUri) => firebaseApp.storage(bucketUri)
const getAuth = () => firebaseApp.auth()
const getFunctions = () => firebaseApp.functions('europe-west3')

/**
 * e.g. gs://yourBucketNameHere
 */
export type BucketUri = string

export const ASSET_BUCKET_URI = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_URI
export const VIDEOS_INPUT_BUCKET_URI = process.env.REACT_APP_FIREBASE_STORAGE_VIDEOS_INPUT_BUCKET_URI

const loggerFirestore = new Logger('firestore')
const loggerStorage = new Logger('storage')
const loggerAuth = new Logger('auth')

const firebaseTimestampFromDate = (date = new Date()) => {
    return firebase.firestore.Timestamp.fromDate(date)
}

const firebaseTimestampFromMs = (timestampMs: number) => {
    const date = timestampMs ? new Date(timestampMs) : new Date()

    return firebase.firestore.Timestamp.fromDate(date)
}

export const firebaseTimestampFromDayjs = (date: Dayjs = dayjs()) => {
    return firebaseTimestampFromDate(date.toDate())
}

export const getCurrentFirebaseTimestamp = () => {
    return firebaseTimestampFromDate(new Date())
}

export type {
    FirestoreCollectionReference,
    FirebaseDocumentData,
    FirestoreDocumentSnapshot,
    FirestoreQueryDocumentSnapshot,
    FirebaseUser,
    FirestoreTimestamp,
    FirestoreQuery,
    FirestoreDocumentReference,
}
export {
    getFunctions,
    getFirestore,
    getStorage,
    getAuth,
    loggerFirestore,
    loggerStorage,
    loggerAuth,
    firebaseTimestampFromDate,
    firebaseTimestampFromMs,
}
