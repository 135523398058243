import { Grid, OutlinedInput } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'

import type { ChangeEvent } from 'react'


const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'uppercase',
    },
    input: {
        width: '14rem',
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& input': {
            MozAppearance: 'textfield',
        },
    },
    icon: {
        fontSize: '1rem',
    },
    label: {
        marginRight: theme.spacing(2),
        color: COLORS.SECONDARY,
    },
}))

type SeatsLeftInputProps = {
    onChange: (seatsLeft: number | null | undefined) => Promise<void> | void
    seatsLeft: number | null | undefined
}

export const SeatsLeftInput: React.FC<SeatsLeftInputProps> = ({ seatsLeft, onChange }) => {
    const classes = useStyles()
    const [t] = useTranslation()

    const handleChangeSeatsLeft = (event: ChangeEvent<HTMLInputElement>) => {
        const re = /^\d+(?:[.,]\d*)?$/
        const value = event.target.value.trim()
        if (value === '') {
            onChange(null)
        } else if (re.test(value) && !isNaN(parseFloat(value))) {
            const seatsLeft = parseInt(event.target.value)
            onChange(isNaN(seatsLeft) ? undefined : seatsLeft)
        }
    }
    return (
        <Grid
            container
            direction="column"
            spacing={1}
        >
            <Grid
                item
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={1}
                        className={classes.label}
                    >
                        {t('Seats left')}
                    </Grid>
                    <Grid item>
                        <OutlinedInput
                            value={seatsLeft ?? ''}
                            type="number"
                            className={classes.input}
                            margin="dense"
                            onChange={handleChangeSeatsLeft}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SeatsLeftInput
