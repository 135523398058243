import { truncateText } from '@tivio/common'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { goMessagesPage } from '../../../utils/history.utils'
import { Header } from '../Chat/Message/Header'
import { Stack } from '../Stack'
import { UserAvatar } from '../UserAvatar'


const Container = styled.div<{ $isActive?: boolean }>`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2.5)}px`};
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 99ms ease-out;
    ${(props) => props.$isActive && 'background-color: rgba(255, 255, 255, 0.0844);'}
    &:hover {
        background-color: rgba(255, 255, 255, 0.0844);
    }
`

const AvatarWrapper = styled.div`
    position: relative;
`

const Unread = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #007bff;
`

const Message = styled.div`
    font-size: 0.875rem;
    color: #fff;
`

export interface Props {
    id: string
    avatarUrl?: string
    name: string
    isUnread: boolean
    date: Date | null
    message: string
    isActive?: boolean
    isLastMessageFromCurrentUser?: boolean
    email: string
}

export const ListItem = ({ id, avatarUrl, name, isUnread, date, message, isActive, isLastMessageFromCurrentUser, email }: Props) => {
    const [t] = useTranslation()
    return (
        <Container
            $isActive={isActive}
            onClick={() => {
                goMessagesPage(id)
            }}
        >
            <AvatarWrapper>
                <UserAvatar
                    name={name}
                    avatarUrl={avatarUrl}
                    size="medium"
                    variant={isActive ? 'opaque' : 'semi-transparent'}
                />
                {isUnread && <Unread />}
            </AvatarWrapper>
            <Stack
                direction="column"
                spacing={0.5}
            >
                <Header
                    createdAt={date}
                    editedAt={null}
                    user={{ name: name, avatarUrl: avatarUrl ?? null, email: email }}
                />
                <Message>
                    {isLastMessageFromCurrentUser ? <em><strong>{t('You')}: </strong></em> : ''}
                    {truncateText(message, 60)}
                </Message>
            </Stack>
        </Container>
    )
}
