import { createLogger, Logger } from '@tivio/common'
import { tivio } from '@tivio/core-js'
import { GET_EPG_INTERVAL_STEP_HOURS } from '@tivio/firebase'
import {
    ChannelSourceParams,
    SourceParams,
    SourcePlayMode,
    SourceType,
    TvChannel,
    TvChannelType,
    TvStreamType,
    Video,
    VirtualChannelSourceParams,
    VodExternalSourceParams,
    VodTivioSourceParams,
} from '@tivio/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { when } from 'mobx'

import { isString } from '../../utils'

import { UseInputSourceOptions } from './types'


dayjs.extend(utc)

interface SourceParamsResult {
    params?: SourceParams,
    video?: Video | null,
    channel?: TvChannel | null
    error?: Error | null
}

export class SourceParamsFactory {
    constructor(
        private readonly source: SourceParams | string,
        private readonly options?: UseInputSourceOptions,
        private readonly logger: Logger = createLogger('SourceParamsFactory'),
    ) {}

    async create(): Promise<SourceParamsResult> {
        if (this.isVideoPath(this.source)) {
            return this.createVODSourceParams(this.source)
        }
        if (this.isChannelPath(this.source)) {
            return this.createChannelSourceParams(this.source)
        }

        return this.createVODExternalSourceParams(this.source)
    }

    private async createVODSourceParams(path: string): Promise<SourceParamsResult> {
        this.logger.info(`Fetching video source based on DB path: "${this.source}"`)

        const video = await tivio.getVideoByPath(path)

        const linkedVideosIsNotLoaded = video?.linkedVideosInNotLoaded()
        if (linkedVideosIsNotLoaded) {
            await video.loadLinkedVideos()
        }

        await when(() => video.isMonetizationsReady)

        try {
            const showTasting = Boolean(video.price && video.tasting)
            const {
                url,
                drm,
                language,
                sourcePlayMode,
            } = showTasting
                ? await video!.tasting!.getSourceUrl()
                : await video.getSourceUrl({ audioOnly: this.options?.audioOnly })

            const params = {
                type: SourceType.VOD_TIVIO,
                adMonetizationId: await this.getAdvertisementId(showTasting ? video.tasting! : video),
                description: video.description,
                videoPath: video.path,
                name: video.name,
                continuePositionMs: this.options?.continuePositionMs,
                availableLanguages: video.sourceLanguages,
                ignoreWatchPosition: showTasting,
                sourcePlayMode,
                language,
                url,
                drm,
            } satisfies VodTivioSourceParams

            return { params, video }
        } catch (error) {
            return { error, video }
        }
    }

    private async createChannelSourceParams(path: string): Promise<SourceParamsResult> {
        this.logger.info(`Fetching channel based on DB path: "${this.source}"`)

        const channel = await tivio.getTvChannelByPath(path)

        switch (channel.type) {
            case TvChannelType.CLASSIC:
                return this.createClassicChannelSourceParams(channel)
            case TvChannelType.VIRTUAL:
                return await this.createVirtualChannelSourceParams(channel)
        }

        return { channel }
    }

    private async createClassicChannelSourceParams(channel: TvChannel): Promise<SourceParamsResult> {
        try {
            const { url, sourcePlayMode } = await channel.getSourceUrl()
            const params = {
                name: channel.name,
                description: channel.description ?? '',
                channelName: channel.name,
                poster: null,
                originalOptions: {},
                type: SourceType.CHANNEL,
                tvMode: TvStreamType.LIVE,
                tvChannelType: TvChannelType.CLASSIC,
                programName: channel.name,
                programDescription: '',
                from: new Date(),
                to: new Date(),
                sourcePlayMode,
                url,
            } satisfies ChannelSourceParams

            return { params, channel }
        } catch (error) {
            return { error }
        }
    }

    private async createVirtualChannelSourceParams(channel: TvChannel): Promise<SourceParamsResult> {
        try {
            const from = dayjs()
            const to = dayjs().add(GET_EPG_INTERVAL_STEP_HOURS, 'hour')
            // TODO: Add a limit=1.
            const epg = await tivio.getEpgTvProgramsByTvChannel({ from, to, tvChannelId: channel.id })
            const currentProgram = epg?.[0]

            const response = await currentProgram?.video?.getSourceUrl()

            const params = {
                name: channel.name,
                description: channel.description ?? '',
                channelName: channel.name,
                poster: null,
                originalOptions: {},
                type: SourceType.CHANNEL,
                tvMode: TvStreamType.LIVE,
                tvChannelType: TvChannelType.VIRTUAL,
                currentProgram,
                programName: currentProgram?.name ?? channel.name,
                programDescription: currentProgram?.description,
                from: currentProgram?.from ?? new Date(),
                to: currentProgram?.to ?? new Date(),
                url: response?.url ?? '',
                autoplay: true,
                drm: response?.drm,
                language: response?.language,
                availableLanguages: currentProgram?.video?.sourceLanguages,
                sourcePlayMode: response?.sourcePlayMode ?? SourcePlayMode.LIVE,
            } satisfies VirtualChannelSourceParams

            return { params, channel }
        } catch (error) {
            return { error }
        }
    }

    private createVODExternalSourceParams(source: SourceParams): SourceParamsResult {
        return { params: source as VodExternalSourceParams }
    }

    private isVideoPath(source: SourceParams | string): source is string {
        // TODO mb regex will be prettier
        return isString(source) && (source.startsWith('videos/') || source.startsWith('/videos/'))
    }

    private isChannelPath(source: SourceParams | string): source is string {
        // TODO mb regex will be prettier
        return isString(source) && (source.startsWith('tvChannels/') || source.startsWith('/tvChannels/'))
    }

    private async getAdvertisementId(video: Video) {
        if (video.adMonetizationId) {
            return video.adMonetizationId
        }

        const isStargazeMasterOrganization = tivio.organization?.isLockedApplication || tivio.organization?.isTivioPro
        if (!isStargazeMasterOrganization || !video.organizationId) {
            return undefined
        }

        const isFreeWithoutMonetization = video.price === 0 && !video.purchasableMonetization?.price
        const hasValidOrganizationPurchase = async () => {
            const ids = await tivio.user?.getSubscriptionIdsByOrganizationId(video.organizationId!)
            return Boolean(ids?.length)
        }

        return isFreeWithoutMonetization && !(await hasValidOrganizationPurchase())
            ? await tivio.organization?.getAdMonetizationId()
            : undefined
    }
}