import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import AccessAlarm from '@material-ui/icons/AccessAlarm'
import { AssetPresetPlacement } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useHistory } from 'react-router-dom'

import { MARKER_TYPES } from '../../static/enum'
import { validators } from '../../utils/seekbar.utils'
import { secondsToTimeString } from '../../utils/time.utils'
import { findParentChapter, onlyChaptersFilter } from '../../utils/vod.utils'
import { useAssetResize } from '../hooks/dataHooks/useAssetResize'
import { useAlert } from '../hooks/uiHooks/useAlert'
import PlayerContainer from '../player/PlayerContainer'
import PlayerControls from '../player/PlayerControls'
import { PlayerMarkerForm } from '../player/PlayerMarkerForm'
import { useMarkerEditorStyles } from '../player/styles.markerEditor'
import { useAllAssetPresets } from '../settings/presets/hooks'

import VideoSeekbarWrapper from './VideoSeekbarWrapper'

import type { MarkerData } from '../../firebase/firestore/markerEpg'
import type { AssetPreset } from '../../store/AssetPreset'
import type Marker from '../../store/Marker'
import type Video from '../../store/Video'
import type { Interval } from '../../utils/epg.utils'
import type { ProgressEvent } from '../asset/assetImageInput'
import type { PlayerController } from '../player/Player'
import type { SeekbarController } from '../player/PlayerSeekbar'
import type { VideoSourceField } from '@tivio/types'
import type { TFunction } from 'i18next'


function sourceToMenuItemLabel(source: VideoSourceField, t: TFunction): string {
    return [
        `priority ${source.priority}`,
        source.protocol,
        source.encryption !== 'none' ? source.encryption : t('no encryption'),
        ...source.languages ?? [],
    ].join(', ')
}

const useStyles = makeStyles((theme) => ({
    playerContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    divider: {
        marginBottom: theme.spacing(2),
        height: 2,
    },
    controlsContainer: {
        paddingBottom: theme.spacing(6),
        position: 'relative',
    },
    timer: {
        position: 'absolute',
        left: 0,
        top: -4,
    },
    sourceContainer: {
        marginBottom: theme.spacing(4),
    },
    sourceSelect: {
        width: 350,
    },
    sourceUrl: {
        fontSize: '0.8rem',
    },
}))

interface Props {
    video: Video
    /**
     * Index of item in array of sources to play
     */
    sourceIndex?: number
    /**
     * Handler to change active tab of video page.
     */
    setActiveTabIndex?: (index: number) => void
    isOrganizationOwner?: boolean
}

export const VideoMarkerEditor: React.FC<Props> = observer(({ video, sourceIndex, setActiveTabIndex, isOrganizationOwner = true }) => {
    const classes = useStyles()
    const classesMarkerEditor = useMarkerEditorStyles()
    const presets = useAllAssetPresets({
        placement: AssetPresetPlacement.VIDEO,
    })
    const preset = presets.find(asset => asset.name === 'cover')!
    const [coverProgressEvent, setCoverProgressEvent] = useState<ProgressEvent | null>(null)
    const { resizeAsset } = useAssetResize({
        preset,
        item: {
            getAsset: video.getAsset,
            getTempFilePath: (preset: AssetPreset) => `${video.getDocumentPath}/${preset.name}.jpeg`,
            getDocumentPath: () => video.getDocumentPath,
            updateItem: async (resizeResult) => {
                video.setCover = resizeResult
            },
        },
        onProgress: setCoverProgressEvent,
    })
    const seekbarRef = useRef<SeekbarController | null>(null)
    const playerRef = useRef<PlayerController | null>(null)
    const [contentDuration, setContentDuration] = useState(0) // in seconds
    const [isPlaying, setIsPlaying] = useState(false)
    const [marker, setMarker] = useState<MarkerData>()
    const [playingPosition, setPlayingPosition] = useState(0) // in seconds
    const [t] = useTranslation()
    const [videoSourceIndex, setVideoSourceIndex] = useState<number>(sourceIndex || 0)
    const [videoSource, setVideoSource] = useState<VideoSourceField | null>(null)
    const { push } = useHistory()
    const { showError, showSuccess } = useAlert()

    const handleCreateMarker = async (markerData: MarkerData) => {
        await video.addMarker(markerData)
    }

    const handleCreateVideoCut = async (markerData: MarkerData) => {
        try {
            if (markerData.type !== MARKER_TYPES.CUT && markerData.type !== MARKER_TYPES.TRAILER && markerData.type !== MARKER_TYPES.TASTING) {
                throw new Error('Invalid marker type. Must be CUT or TRAILER.')
            }
            const videoRef = await video.createCut(
                markerData.from * 1000,
                markerData.to * 1000,
                markerData.type,
            )
            setActiveTabIndex?.(0)
            push(`/videos/${videoRef.id}`)
        } catch (e) {
            throw new Error(`Couldn't create video cut: ${e.message}`)
        }
    }

    const handleCreateChapter = async (markerData: MarkerData) => {
        const chapters = video.getMarkers.filter(onlyChaptersFilter)

        if (chapters.length === 0) {
            await video.addMarker(markerData)
            await video.addMarker({
                type: MARKER_TYPES.CHAPTER,
                from: markerData.to as number,
                to: contentDuration,
            })
        } else {
            const parentChapter = findParentChapter(
                chapters,
                markerData.from,
                markerData.to,
            )

            if (parentChapter) {
                if (
                    parentChapter.getFrom !== markerData.from &&
                    parentChapter.getTo !== markerData.to
                ) {
                    await video.addMarker(markerData)
                    await video.addMarker({
                        type: MARKER_TYPES.CHAPTER,
                        from: markerData.to as number,
                        to: parentChapter.getTo,
                        name: t('Chapter'),
                    })

                    await parentChapter.updateMarker({
                        to: markerData.from as number,
                    })

                    return
                } else {
                    await parentChapter.updateMarker({
                        from: markerData.to as number,
                    })

                    await video.addMarker(markerData)
                }
            }
        }
    }

    const handleMarkerFormSubmit = async (markerData: MarkerData) => {

        if (markerData.type === MARKER_TYPES.CHAPTER) {
            await handleCreateChapter(markerData)
        } else if ([MARKER_TYPES.CUT, MARKER_TYPES.TRAILER, MARKER_TYPES.TASTING].includes(markerData.type)) {
            await handleCreateVideoCut(markerData)
        } else {
            await handleCreateMarker(markerData)
        }

        handleCloseMarkerForm()
    }

    const handleCloseMarkerForm = () => {
        setMarker(undefined)
        seekbarRef.current?.resetInterval()
    }

    const handleIntervalUpdate = (interval: Interval) => {
        seekbarRef.current?.setInterval(interval)
    }

    const handleDeleteChapter = async (chapter: Marker) => {
        const chapters = video.getMarkers.filter(onlyChaptersFilter)

        if (chapters.length === 2) {
            await Promise.all(
                chapters.map(
                    async chapter => {
                        await video.deleteMarker(chapter)
                    },
                ),
            )
        } else {
            const prevChapter = chapters.find(findChapter => findChapter.getTo === chapter.getFrom)
            const nextChapter = chapters.find(findChapter => findChapter.getFrom === chapter.getTo)

            const actions: (() => Promise<void>)[] = []

            if (prevChapter && nextChapter) {
                actions.push(
                    async () => prevChapter.updateMarker({
                        to: chapter.getTo,
                    }),
                )
            } else if (prevChapter) {
                actions.push(
                    async () => prevChapter.updateMarker({
                        to: chapter.getTo,
                    }),
                )
            } else if (nextChapter) {
                actions.push(
                    async () => nextChapter.updateMarker({
                        from: chapter.getFrom,
                    }),
                )
            }


            await video.deleteMarker(chapter)
            await Promise.all(
                actions.map(async action => action()),
            )
        }
    }

    const handleDeleteMarker = async (marker: Marker) => {
        if (marker.getType === MARKER_TYPES.CHAPTER) {
            await handleDeleteChapter(marker)
        } else {
            await video.deleteMarker(marker)
        }

        handleCloseMarkerForm()
    }

    const handleUpdateChapter = async (chapter: Marker, chapterData: MarkerData) => {
        const chapters = video.getMarkers.filter(onlyChaptersFilter)

        const prevChapter = chapters.find(findChapter => findChapter.getTo === chapter.getFrom)
        const nextChapter = chapters.find(findChapter => findChapter.getFrom === chapter.getTo)

        const actions: (() => Promise<void>)[] = []

        if (nextChapter) {
            actions.push(
                async () => nextChapter.updateMarker({
                    from: chapterData.to as number,
                }),
            )
        }

        if (prevChapter) {
            actions.push(
                async () => prevChapter.updateMarker({
                    to: chapterData.from,
                }),
            )
        }

        if (
            chapter.getFrom > chapterData.from ||
            chapter.getTo < chapterData.to
        ) {
            // the same as in else but in a different order: we need to make the one chapter smaller and than make
            // the other chapter bigger otherwise it breaks layout for the moment because they don't fit
            await Promise.all(
                actions.map(async action => action()),
            )
            await chapter.updateMarker(chapterData)
        } else {
            await chapter.updateMarker(chapterData)
            await Promise.all(
                actions.map(async action => action()),
            )
        }
    }

    const handleMarkerUpdate = async (marker: Marker, markerData: MarkerData) => {
        if (marker.getType === MARKER_TYPES.CHAPTER) {
            await handleUpdateChapter(marker, markerData)
        } else {
            await marker.updateMarker(markerData)
        }

        handleCloseMarkerForm()
    }

    const handleCoverChange = async (cover: Blob) => {
        await resizeAsset(new File([cover], 'cover')) // this function changes coverProgressEvent state
        handleCloseMarkerForm()
    }

    // Status message when cover is changed.
    useEffect(() => {
        if (coverProgressEvent?.state === 'error') {
            showError(t('Couldn\'t create cover. Try again.'))
        } else if (coverProgressEvent?.state === 'success') {
            showSuccess(t('Cover created.'))
        }
    }, [coverProgressEvent, showError, showSuccess, t])

    const seekPlayer = (sec: number) => {
        playerRef.current?.seek(sec)
    }

    const handleSeek = (sec: number) => {
        setPlayingPosition(sec)
        seekPlayer(sec)
    }

    const handleUpdatePlayingPosition = (step: number) => {
        const newFromPosition = playingPosition + step

        if (
            validators.isEqualToZero(playingPosition) ||
            !validators.isGreaterThanZero(newFromPosition) ||
            !validators.isLesserThanDuration(newFromPosition, contentDuration)
        ) {
            return
        }

        handleSeek(newFromPosition)
    }

    const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const index = parseInt(event.target.value as string)
        setIsPlaying(false)
        setVideoSourceIndex(index)
    }

    const loadSource = async () => {

        const source = await video.getSourceUrl()
        setVideoSource(video.sources?.[videoSourceIndex]
            ?? source.data.url
            ?? await video.getInputUrlLink()
            ?? video.getUrl?.hls
            ?? video.getUrl?.dash
            ?? video.getUrl?.source
            ?? null)
        
    }

    useEffect(() => {
        loadSource()
    }, [])

    // Source auto-load
    useEffect(() => {
        if (!videoSource) {
            return
        }

        const sourceHistory = typeof videoSource !== 'string'
            ? video.sources.filter(s => s.url !== videoSource.url).map(s => s.url)
            : undefined

        playerRef.current?.load({
            source: videoSource,
            documentType: 'video',
            positionSec: playingPosition,
            documentId: video.ref.id,
            sourceHistory,
        })
    }, [video.ref.id, videoSource])

    return (
        <>
            <Container
                maxWidth="lg"
                className={classes.playerContainer}
            >
                <PlayerContainer
                    error={{ error: !videoSource }}
                    onDuration={setContentDuration}
                    onPause={() => setIsPlaying(false)}
                    onPlay={() => setIsPlaying(true)}
                    onTimeUpdate={setPlayingPosition}
                    playerRef={playerRef}
                />
            </Container>
            <div className={classesMarkerEditor.container}>
                {(marker && isOrganizationOwner) && (
                    <div className={classesMarkerEditor.addMarkerContainer}>
                        <PlayerMarkerForm
                            contentDuration={contentDuration}
                            isPlaying={isPlaying}
                            isVideoCut={!!video.isCut}
                            marker={marker}
                            markers={video.getMarkers}
                            videoElement={playerRef.current?.domElement}
                            onClose={handleCloseMarkerForm}
                            onDelete={handleDeleteMarker}
                            onIntervalChange={handleIntervalUpdate}
                            onMarkerUpdate={handleMarkerUpdate}
                            onPause={() => playerRef.current?.pause()}
                            onPlay={() => playerRef.current?.play()}
                            onSeekWithControls={handleSeek}
                            onPlayingPositionChange={handleSeek}
                            onMarkerFormSubmit={handleMarkerFormSubmit}
                            onCoverChange={handleCoverChange}
                            coverProgressEvent={coverProgressEvent}
                        />
                    </div>
                )}
                <Divider className={classes.divider}/>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.controlsContainer}
                    >
                        <Button
                            startIcon={<AccessAlarm/>}
                            className={classes.timer}
                        >
                            {secondsToTimeString(playingPosition, false)}
                        </Button>
                        <Grid item>
                            <PlayerControls
                                onSeekWithControls={handleUpdatePlayingPosition}
                                onPause={() => playerRef.current?.pause()}
                                onPlay={() => playerRef.current?.play()}
                                isPlaying={isPlaying}
                            />
                        </Grid>
                    </Grid>
                </Container>
                {
                    contentDuration !== 0 && (
                        <VideoSeekbarWrapper
                            contentDuration={contentDuration}
                            isMarkerFormOpen={!!marker}
                            mouseContainerRef={seekbarRef}
                            onSeek={handleSeek}
                            onSeekbarClick={setMarker}
                            playingPosition={playingPosition}
                            video={video}
                            disabled={!isOrganizationOwner}
                        />
                    )
                }
            </div>
            {(video.sources?.length > 0) && (
                <Container
                    maxWidth="lg"
                    className={classes.sourceContainer}
                >
                    <Box
                        mb={1}
                        textAlign="center"
                    >
                        <FormControl
                            variant="outlined"
                            className={classes.sourceSelect}
                            size="small"
                            title={t('Change the source to play')}
                        >
                            <InputLabel id="videoSource">Source</InputLabel>
                            <Select
                                labelId="videoSource"
                                label="Source"
                                id="videoSourceSelect"
                                value={videoSourceIndex}
                                onChange={handleSourceChange}
                                fullWidth
                            >
                                {video.sources.map((source, index) => (
                                    <MenuItem
                                        key={index}
                                        value={index}
                                    >
                                        {sourceToMenuItemLabel(source, t)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box textAlign="center">
                        <Typography
                            color="textSecondary"
                            className={classes.sourceUrl}
                        >{video.sources?.[videoSourceIndex].url}</Typography>
                    </Box>
                </Container>
            )}
        </>
    )
})
