import { createBrowserHistory } from 'history'

import { PAGES } from '../static/enum'

import { getElementPath, getSectionPath } from './id.utils'

import type Section from '../store/Section'
import type User from '../store/User'
import type Video from '../store/Video'


const history = createBrowserHistory()

export const goDeployPage = () => {
    history.push(PAGES.DEPLOY)
}

export const goAnalyticsPage = () => {
    history.push(PAGES.ANALYTICS)
}

export const goApplicationsPage = () => {
    history.push(PAGES.APPLICATIONS)
}

export const goArticlesPage = () => {
    history.push(PAGES.ARTICLES)
}

export const goFeedPage = () => {
    history.push(PAGES.FEED)
}

export const goArticlePage = (articleId: string) => {
    history.push(`${PAGES.ARTICLES}/${articleId}`)
}

export const goScreensPage = () => {
    history.push(PAGES.SCREENS)
}

export const goScreenPage = (screenId: string) => {
    history.push(`/screens/${screenId}`)
}

export const goVideosPage = () => {
    history.push('/videos')
}

export const goChannelsPage = () => {
    history.push('/channels')
}

export const goChannelPage = (channelId: string) => {
    history.push(`/channels/${channelId}`)
}

export const goEpgChannelPage = (epgChannelId: string) => {
    history.push(`/epg-channel/${epgChannelId}`)
}

export const goWidgetsPage = () => {
    history.push('/widgets')
}

export const goWidgetPage = (id: string) => {
    history.push(`/widget/${id}`)
}

export const goMembershipsPage = () => {
    history.push('/monetization/memberships')
}

export const goSubscriptionsPage = () => {
    history.push('/monetization/subscriptions')
}

export const goSubscriptionPage = (id: string) => {
    history.push(`/monetization/subscriptions/${id}`)
}

export const goTransactionsPage = () => {
    history.push('/monetization/transactions')
}

export const goPromotionsPage = () => {
    history.push('/monetization/promotions')
}

export const goTransactionPage = (id: string) => {
    history.push(`/monetization/transactions/${id}`)
}

export const goAdsPage = () => {
    history.push('/monetization/ads')
}

export const goAdProfileCreatePage = () => {
    history.push('/monetization/ad-profile/create')
}

export const goAdProfileDetailPage = (id: string) => {
    history.push(`/monetization/ad-profile/${id}`)
}

export const goBack = () => {
    history.goBack()
}

export const goLoginPage = () => {
    history.push(PAGES.LOGIN)
}

export const goUserNotFoundPage = () => {
    history.push(PAGES.USER_NOT_FOUND)
}

export const goTOSConsentPage = () => {
    history.push(PAGES.TOS_CONSENT, {
        redirect: history.location.pathname,
    })
}

/**
 * Redirects browser path to section video edit page.
 * Does nothing if video is not part of a section.
 *
 * @param video Video store object.
 */
export const goSectionVideoPage = (video: Video) => {
    if (!video.section) {
        return
    }

    const {
        sectionId,
        channelId,
        elementId,
    } = getElementPath(video)

    history.push(`/channels/${channelId}/section/${sectionId}/video/${elementId}`)
}

/**
 * Redirects browser path to global video edit page.
 *
 * @param video Video store object.
 */
export const goVideoPage = (videoId: string) => {
    history.push(`/videos/${videoId}`)
}

export const goUpdatePlaylistPage = (playlist: Video) => {
    if (!playlist.section) {
        return
    }

    const {
        sectionId,
        channelId,
        elementId,
    } = getElementPath(playlist)

    history.push(`/channels/${channelId}/section/${sectionId}/playlist/${elementId}`)
}

export const goRootPage = () => {
    history.push('/')
}

export const goSectionPage = (section: Section) => {
    const { channelId, sectionId } = getSectionPath(section)

    history.push(`/channels/${channelId}/section/${sectionId}`)
}

export const isUserNotFoundPage = () => {
    return history.location.pathname === PAGES.USER_NOT_FOUND
}

export const goSettingsPage = () => {
    history.push(PAGES.SETTINGS)
}

export const goMonetizationPage = (section: string) => {
    history.push(`/monetization/${section}`)
}

export const openPatreonPatronLogin = () => window.location.replace(`${process.env.REACT_APP_PATREON_ENDPOINT}/authorize?response_type=code&client_id=${process.env.REACT_APP_PATREON_CLIENT_ID}&redirect_uri=${window.location.origin + window.location.pathname}&scope=identity+identity%5Bemail%5D+identity.memberships+campaigns+campaigns.members+w:campaigns.webhook`)

export const goSecretsPage = () => {
    history.push(PAGES.SECRETS)
}

export const goUsersPage = () => {
    history.push(PAGES.USERS)
}

export const goUserPage = (user: User) => {
    history.push(`${PAGES.USERS}/${user.id}`)
}

export const goServicesPage = () => {
    history.push(PAGES.SERVICES)
}

export const goOrganizationSettingsPage = () => {
    history.push(PAGES.ORGANIZATION_SETTINGS)
}

export const goMultiUploadPage = () => {
    history.push(PAGES.MULTIUPLOAD)
}

export const goRoadmapPage = () => {
    history.push(PAGES.ROADMAP)
}

export const goCommunityPage = () => {
    history.push(PAGES.COMMUNITY)
}

export const goMessagesPage = (conversationId: string) => {
    history.push(PAGES.COMMUNITY_MESSAGES.replace(':conversationId', conversationId))
}

export default history
