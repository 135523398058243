import styled from 'styled-components'


const reduceSpacing = (spacing: number | string) => {
    const spacingValue = parseFloat(spacing.toString())
    return spacingValue * 0.25 + 'rem'
}

export const Stack = styled.div<{ direction: 'column' | 'row'; spacing?: number | string, alignItems?: string }>`
    display: flex;
    flex-direction: ${({ direction }) => direction};
    ${({ spacing }) => spacing && `gap: ${reduceSpacing(spacing)}`};
`
