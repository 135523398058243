import { app, mapIndexedAlgoliaObjects } from '@tivio/core-js'
import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import { useCallback, useState } from 'react'

import { loadApplicationsFromAlgoliaHits } from '../../utils'

import { getFiltersByIndex, useAlgoliaRecommend } from './algolia'
import { useError } from './useError'
import { useUser } from './useUser'

import type { RecommendationsQuery } from '@algolia/recommend'
import type { PaginationInterface, Video } from '@tivio/types'
import { STARGAZE_ORGANIZATION_ID } from '@tivio/common'


// This could potentially be expanded to work with tags and tv channels in the future
export function useSimilars(shouldLoadApplicationsOfOrganizations?: boolean, isVideoSharedFromAnotherOrganization?: boolean) {
    const { user } = useUser()
    const [pagination, setPagination] = useState<PaginationInterface<Video>>({
        // TODO why cant i use undefined here (mb change pagination interface type)
        items: [],
        // TODO no real pagination only first results
        fetchMore: () => { },
    })
    const { error, raiseError, resetError } = useError()
    const { getRecommendations } = useAlgoliaRecommend()

    const getSimilars = useCallback(async ({
        videoId,
        similarsQuery,
    }: {
        videoId: string
        similarsQuery?: RecommendationsQuery
    }) => {
        resetError()

        setPagination(prev => ({
            ...prev,
            loading: true,
        }))

        try {
            const isStargazeWebsite = app.organization?.originalOrganizationId === STARGAZE_ORGANIZATION_ID && app.organization.isTivioPro
            if (isVideoSharedFromAnotherOrganization && !isStargazeWebsite) {
                return setPagination(prev => ({
                    ...prev,
                    items: [],
                    loading: false,
                }))
            }
            const response = await getRecommendations({
                objectID: videoId,
                indexName: ALGOLIA_INDEX_NAME.VIDEOS,
                maxRecommendations: 20,
                queryParameters: {
                    filters: getFiltersByIndex(ALGOLIA_INDEX_NAME.VIDEOS),
                    userToken: user?.id && user?.activeUserProfileId
                        ? `${user.id}-${user.activeUserProfileId}`
                        : user?.id,
                },
                ...similarsQuery,
            })

            if (shouldLoadApplicationsOfOrganizations) {
                await loadApplicationsFromAlgoliaHits(response.results[0].hits)
            }

            const videos = await mapIndexedAlgoliaObjects({
                indexName: ALGOLIA_INDEX_NAME.VIDEOS,
                indexedObjects: response.results[0].hits,
            })

            setPagination(prev => ({
                ...prev,
                items: videos,
            }))
        } catch (error) {
            raiseError(error)
            // @ts-expect-error TODO change pagination type to accept undefined, it is a valid state (items are not yet loaded)
            setPagination(prev => ({
                ...prev,
                items: undefined,
            }))
        } finally {
            setPagination(prev => ({
                ...prev,
                loading: false,
            }))
        }
    }, [getRecommendations,
        isVideoSharedFromAnotherOrganization,
        raiseError,
        resetError,
        shouldLoadApplicationsOfOrganizations,
        user?.activeUserProfileId,
        user?.id
    ])

    return {
        getSimilars,
        pagination,
        error,
    }
}
