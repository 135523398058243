import { Fab } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { observer } from 'mobx-react'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'

import { Stack } from '../Stack'

import { AddMessage } from './AddMessage'
import { Message } from './Message'

import type { Conversation } from '@tivio/types'


const Relative = styled.div`
    position: relative;
`

const Container = styled.div`
    background-color: rgba(255, 255, 255, 0.0844);
    border-radius: 10px;
    padding: ${({ theme }) => `${theme.spacing(4)}px`};
    height: calc(100vh - 300px);
    overflow-y: auto;
`

const Heading = styled.h1`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
`

const ScrollButton = styled(Fab)`
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;

    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.dark};
    }
`

interface Props {
    conversation: Conversation
    userPath: string
}

export const Chat = observer(({ conversation, userPath }: Props) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const hasScrolled = React.useRef(false)
    const [showScrollButton, setShowScrollButton] = useState(false)
    const lastMessageIdRef = React.useRef<string | null>(null)
    const { messages } = conversation

    const scrollToBottom = (instant: boolean) => {
        if (ref.current) {
            const scrollOptions: ScrollToOptions = {
                top: ref.current.scrollHeight,
                behavior: instant ? 'auto' : 'smooth',
            }
            ref.current.scrollTo(scrollOptions)
        }
    }

    const handleScroll = () => {
        if (ref.current) {
            const isAtBottom = ref.current.scrollHeight - ref.current.scrollTop <= ref.current.clientHeight + 100
            setShowScrollButton(!isAtBottom)
        }
    }

    useEffect(() => {
        conversation.subscribeToMessages()

        return () => {
            conversation.destroy()
        }
    }, [conversation])

    useLayoutEffect(() => {
        if (messages.length > 0) {
            const { id: messageId } = messages[messages.length - 1] ?? {}
            if (messageId && lastMessageIdRef.current !== messageId) {
                conversation.markAsRead(messageId)
                lastMessageIdRef.current = messageId
            }
        }
        if (hasScrolled.current) {
            scrollToBottom(false)
        } else {
            scrollToBottom(true)
            hasScrolled.current = true
        }
    }, [messages.length])

    useEffect(() => {
        hasScrolled.current = false
    }, [conversation.id])

    useEffect(() => {
        const container = ref.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll)
            }
            conversation.destroy()
        }
    }, [conversation.id])

    return (
        <Stack
            direction="column"
            spacing={2}
        >
            <Heading>Chat</Heading>
            <Relative>
                <Container ref={ref}>
                    <Stack
                        spacing={3}
                        direction="column"
                    >
                        {messages.map((message) => (
                            <Message
                                key={message.id}
                                createdAt={message.createdAt}
                                editedAt={message.editedAt}
                                text={message.text}
                                user={message.participant}
                                isRightSide={message.participant.path === userPath}
                            />
                        ))}
                    </Stack>
                </Container>
                {showScrollButton && (
                    <ScrollButton
                        size="small"
                        onClick={() => scrollToBottom(false)}
                    >
                        <KeyboardArrowDownIcon />
                    </ScrollButton>
                )}
            </Relative>
            <AddMessage conversation={conversation} />
        </Stack>
    )
})
