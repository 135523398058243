import { action, computed, makeObservable } from 'mobx'

import { participantStore } from './ParticipantStore'

import type { MessageParticipant } from './MessageParticipant'
import type { DocumentReference, MessageDocument } from '@tivio/firebase'
import type { ConversationMessage, MessageUser } from '@tivio/types'


export class Message implements ConversationMessage {
    private _participant: MessageParticipant

    constructor(private _ref: DocumentReference<MessageDocument>, private _data: MessageDocument) {
        makeObservable(this, {
            text: computed,
            participant: computed,
            createdAt: computed,
            editedAt: computed,
            repliesCount: computed,
            videoTime: computed,
            reactions: computed,
            id: computed,
            updateRepliesCount: action,
        })

        this._participant = participantStore.getParticipant(_data.fromUserRef, {
            avatarUrl: _data.user?.avatarUrl,
            name: _data.user?.name,
        })
    }

    get participant(): MessageParticipant {
        return this._participant
    }

    get text(): string {
        return this._data.text ?? ''
    }

    get createdAt(): Date {
        return this._data?.createdAt.toDate() ?? new Date()
    }

    get editedAt(): Date | null {
        return this._data.editedAt?.toDate() ?? null
    }

    get repliesCount(): number {
        return this._data?.repliesCount ?? 0
    }

    get videoTime(): number | null {
        return this._data?.videoTime ?? null
    }

    get reactions(): Record<string, number> {
        throw new Error('Not implemented')
    }

    updateRepliesCount = (increment: boolean) => {
        const newRepliesCount = this.repliesCount + (increment ? 1 : -1)
        this._data.repliesCount = newRepliesCount
    }

    get user(): MessageUser | null {
        const user = this._data?.user
        if (!user) {
            return null
        }

        return {
            name: user.name,
            avatarUrl: user.avatarUrl ?? null,
            email: user.email ?? '',
        }
    }

    get id(): string {
        return this._ref.id
    }

    get isReady(): boolean {
        return this.user !== null
    }
}
