import {
    TaskDocument,
    TaskType,
} from '@tivio/firebase'
import { makeAutoObservable } from 'mobx'

import type firebase from 'firebase/app'


class Task {
    constructor(
        private ref: firebase.firestore.DocumentReference<TaskDocument<TaskType>>,
        private data: TaskDocument<TaskType>,
    ) {

        makeAutoObservable(this)
    }

    updateTask = async (data: Partial<TaskDocument<TaskType>>) => {
        await this.ref.update(data)
        this.data = {
            ...this.data,
            ...data,
        }
    }

    get id() {
        return this.ref.id
    }

    get type() {
        return this.data.type
    }

    get contentRef() {
        return this.data.contentRef
    }

    get googleTaskId() {
        return this.data.googleTaskId
    }

    get status() {
        return this.data.status
    }

    get payload() {
        return this.data.payload
    }

    get scheduledTime() {
        return this.data.scheduledTime
    }

    get executedTime() {
        return this.data.executedTime
    }
}

export {
    Task,
}
