import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import { Stack } from '../../Stack'
import { UserAvatar } from '../../UserAvatar'

import { Header } from './Header'

import type { MessageUser } from '@tivio/types'


interface Props {
    user: MessageUser
    text?: string
    createdAt: Date | null
    editedAt: Date | null
    isRightSide: boolean
}

const MessageContainer = styled.div<{ isOwnMessage: boolean }>`
    display: flex;
    align-items: stretch;
    justify-content: ${({ isOwnMessage }) => (isOwnMessage ? 'flex-end' : 'flex-start')};
    margin-bottom: 16px;
    gap: 12px;
`

const MessageBubble = styled.div<{ isOwnMessage: boolean }>`
    max-width: calc(100% - 60px);
    padding: 12px 16px;
    border-radius: 16px;
    background-color: ${({ isOwnMessage, theme }) =>
        isOwnMessage ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.0844)'};
    color: ${({ isOwnMessage, theme }) =>
        isOwnMessage ? theme.palette.primary.contrastText : theme.palette.text.primary};
    ${({ isOwnMessage }) =>
        isOwnMessage ? 'border-top-right-radius: 4px;' : 'border-top-left-radius: 4px;'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    word-wrap: break-word;
`

export const Message = observer(({ user, text, createdAt, editedAt, isRightSide }: Props) => {
    return (
        <MessageContainer isOwnMessage={isRightSide}>
            {!isRightSide && (
                <UserAvatar
                    name={user.name}
                    avatarUrl={user.avatarUrl ?? undefined}
                    size="medium"
                />
            )}
            <Stack
                direction="column"
                spacing={1}
                style={{
                    alignItems: isRightSide ? 'flex-end' : 'flex-start',
                    flex: 1,
                }}
            >
                <Header
                    user={user}
                    createdAt={createdAt}
                    editedAt={editedAt}
                />
                <MessageBubble isOwnMessage={isRightSide}>
                    <Typography>{text}</Typography>
                </MessageBubble>
            </Stack>
        </MessageContainer>
    )
})
