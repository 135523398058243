import { alpha } from '@material-ui/core'
import { getNameAbbreviation } from '@tivio/common'
import React from 'react'
import styled from 'styled-components'


type UserAvatarVariant = 'semi-transparent' | 'opaque'
type SizeType = 'small' | 'medium'

const getPxFromSize = ({ size }: { size: SizeType }) => {
    if (size === 'small') {
        return '32px'
    } else {
        return '48px'
    }
}

const Circle = styled.div<{ size: SizeType; variant: UserAvatarVariant; avatarUrl?: string | null }>`
    flex: none;
    width: ${getPxFromSize};
    height: ${getPxFromSize};
    ${({ variant, theme }) =>
        variant === 'semi-transparent'
            ? `background-color: ${alpha(theme.palette.text.primary, 0.2)};`
            : `background-color: ${alpha(theme.palette.text.primary, 0.69)};`}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: ${({ avatarUrl }) => (avatarUrl ? `url(${avatarUrl})` : 'none')};
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const Initials = styled.div<{ variant: UserAvatarVariant; size: SizeType }>`
    font-weight: 600;
    font-size: ${({ size }) => (size === 'small' ? '12px' : '16px')};
    color: ${({ theme, variant }) => (variant === 'semi-transparent' ? theme.palette.text.primary : theme.palette.background.default)};
`

interface Props {
    name: string
    avatarUrl?: string
    variant?: UserAvatarVariant
    size?: SizeType
}

export const UserAvatar: React.FC<Props> = ({ name, size = 'medium', variant = 'semi-transparent', avatarUrl }) => {
    const abbr = getNameAbbreviation(name ?? '')

    return (
        <Circle
            size={size}
            variant={variant}
            avatarUrl={avatarUrl}
        >
            {!avatarUrl && (
                <Initials
                    size={size}
                    variant={variant}
                >
                    {abbr}
                </Initials>
            )}
        </Circle>
    )
}
