import { List } from '@material-ui/core'
import { EncodingProcessPayload, ProcessDocument } from '@tivio/firebase'
import { VideoEncodingProcessLoading } from './VideoEncodingProcessLoading'
import React from 'react'


interface Props {
    encodingProcesses: ProcessDocument<EncodingProcessPayload>[]
}

export const VideoEncodingProcess = (props: Props) => {
    return (
        <List>
            {props.encodingProcesses.map((process, key) => (
                <VideoEncodingProcessLoading 
                    encodingProcess={process} 
                    key={key}
                />
            ))}
        </List>
    )
}