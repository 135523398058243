import { makeObservable, observable, runInAction } from 'mobx'

import { MessageParticipant } from './MessageParticipant'
import store from './store'

import type { MessageParticipantOptions } from './MessageParticipant'
import type { DocumentReference, OrganizationDocument, UserDocument } from '@tivio/firebase'


class ParticipantStore {
    public cache = observable.map<string, MessageParticipant>()

    constructor() {
        makeObservable(this, {
            cache: observable,
        })
    }

    getParticipant(ref: DocumentReference<UserDocument | OrganizationDocument>, options?: MessageParticipantOptions): MessageParticipant {
        const cachedParticipant = this.cache.get(ref.path)
        if (cachedParticipant) {
            return cachedParticipant
        }

        const newParticipant = new MessageParticipant(ref, undefined, options)
        this.cache.set(ref.path, newParticipant)

        runInAction(async () => {
            try {
                const data = await ref.get()
                newParticipant.setData(data.data() as UserDocument | OrganizationDocument)
            } catch (error) {
                this.cache.delete(ref.path)
                store.getAlert.showAlert((error as Error).message)
            }
        })

        return newParticipant
    }

    clearCache() {
        runInAction(() => {
            this.cache.clear()
        })
    }

    removeParticipant(path: string) {
        runInAction(() => {
            this.cache.delete(path)
        })
    }
}

export const participantStore = new ParticipantStore()
