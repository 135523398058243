import { VideoHighlightsService } from '@tivio/core-js'
import { useEffect, useState } from 'react'

import type { VideoHighlight } from '@tivio/types'


interface UseRecentReplaysProps {
    videoId: string
}

const videoHighlightService = new VideoHighlightsService()

export const useRecentReplays = ({ videoId }: UseRecentReplaysProps) => {
    const [highlights, setHighlights] = useState<VideoHighlight[]>([])

    useEffect(() => {
        let unsubscribe: (() => void) | undefined

        const setupSubscription = async () => {
            unsubscribe = await videoHighlightService.subscribeToVideoHighlights(
                videoId,
                (newHighlights: VideoHighlight[]) => {
                    setHighlights(newHighlights)
                },
            )
        }

        setupSubscription()

        return () => {
            if (unsubscribe) {
                unsubscribe()
            }
        }
    }, [videoId])

    return { highlights }
}