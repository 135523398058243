import { Box, Button, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'

import { COLORS } from '../../../static/enum'
import { hasOktagonLiveTag } from '../../../utils/video.utils'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { StyledContainer } from '../../DefaultPageContainer'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { DefaultPageTabs } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks'
import { useVideo } from '../../hooks/dataHooks/useVideo'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { LiveBadge } from '../../video/videoAnalytics/LiveBadge'
import { VideoAnalytics } from '../../video/videoAnalytics/VideoAnalytics'
import { VideoMarkerEditor } from '../../video/VideoMarkerEditor'
import { VideoSettings } from '../../video/VideoSettings'


import type Video from '../../../store/Video'
import type { PageTab } from '../../DefaultPageTabs'
import type { UserLayoutParams } from '../layout/UserLayout'


const VideoSkeleton: React.FC = () => (
    <StyledContainer maxWidth="lg">
        {/* Tabs: */}
        <Box mb={1}>
            <Grid
                container
                spacing={2}
            >
                <Grid item><Skeleton
                    width={160}
                    height={50}
                /></Grid>
                <Grid item><Skeleton
                    width={160}
                    height={50}
                /></Grid>
            </Grid>
        </Box>
        {/* Video info */}
        <Box mb={6}>
            <Skeleton
                width="100%"
                height={130}
                variant="rect"
            />
        </Box>
        {/* Tags */}
        <Box mb={6}>
            <Box mb={1}>
                <Skeleton
                    width={100}
                    height={40}
                />
            </Box>
            <Grid
                container
                spacing={2}
            >
                <Grid item><Skeleton
                    width={130}
                    height={30}
                /></Grid>
                <Grid item><Skeleton
                    width={130}
                    height={30}
                /></Grid>
                <Grid item><Skeleton
                    width={130}
                    height={30}
                /></Grid>
                <Grid item><Skeleton
                    width={130}
                    height={30}
                /></Grid>
            </Grid>
        </Box>
        {/* Assets */}
        <Box mb={6}>
            <Box mb={1}>
                <Skeleton
                    width={100}
                    height={40}
                />
            </Box>
            <Box mb={1}><Skeleton
                width="100%"
                height={100}
                variant="rect"
            /></Box>
            <Box mb={1}><Skeleton
                width="100%"
                height={100}
                variant="rect"
            /></Box>
            <Box mb={1}><Skeleton
                width="100%"
                height={100}
                variant="rect"
            /></Box>
        </Box>
    </StyledContainer>
)

const VideoPage: React.FC = observer(() => {
    const { videoId } = useParams<UserLayoutParams>()
    const { video, error } = useVideo(videoId)
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [videoSourceIndex, setVideoSourceIndex] = useState(0)
    const { confirmAction } = useConfirmAction()
    const hasLiveTag = video && hasOktagonLiveTag(video)

    const handlePlaySource = (index: number) => {
        setVideoSourceIndex(index)
        setActiveTabIndex(1)
    }

    const isLive = useMemo(() => {
        const now = new Date()
        const availability = video?.availability
        if (!availability || !hasLiveTag) {
            return false
        }

        const hasStarted = availability.from <= now
        const hasEnded = availability.to && availability.to <= now
        return hasStarted && (!availability.to || !hasEnded)
    }, [video, video?.availability, hasLiveTag])

    const liveStreamButton = useMemo(() => {
        const onStartStream = () => {
            video?.setAvailability({ from: dayjs().startOf('day').toDate(), to: null })
            video?.setManualBlock(false)
        }

        const onStopStream = () => {
            const now = new Date()
            confirmAction(
                () => {
                    video?.setAvailability({ to: now })
                },
                t('Are you sure you want to stop the livestream?'),
            )
        }

        if (!hasLiveTag) {
            return null
        }

        if (isLive) {
            return (
                <Button
                    style={{ backgroundColor: COLORS.LIVE_BUTTON, color: 'white' }}
                    variant="contained"
                    onClick={onStopStream}
                >
                    {t('STOP LIVESTREAM')}
                </Button>
            )
        }

        return (
            <Button
                variant="contained"
                onClick={onStartStream}
            >
                {t('START LIVESTREAM')}
            </Button>
        )
    }, [hasLiveTag, isLive, t, video, video?.availability])

    const pageTabs = (video: Video): PageTab[] => {
        const isOrganizationOwner = video.data.organizationRef.id === organization?.id
        return [
            {
                title: t('Settings'),
                description: t('Basic video settings'),
                component: <VideoSettings
                    video={video}
                    onPlay={handlePlaySource}
                    isOrganizationOwner={isOrganizationOwner}
                />,
            },
            {
                title: t('Video'),
                description: t('Video preview and marker editor'),
                component: <VideoMarkerEditor
                    video={video}
                    sourceIndex={videoSourceIndex}
                    setActiveTabIndex={setActiveTabIndex}
                    isOrganizationOwner={isOrganizationOwner}
                />,
                disabled: !video.hasSources && !video.inputFileUrl,
            },
            {
                title: t('Analytics'),
                description: t('Video analytics'),
                component: <VideoAnalytics
                    video={video}
                    isLive={isLive}
                />,
                disabled: !video.hasSources,
            },
        ]
    }

    useEffect(() => {
        if (video) {
            video.subscribe()
            return () => {
                video.unsubscribe()
            }
        }
    }, [video])

    if (error) {
        return (
            <AppNotFoundTemplate title={t('Video not found')} />
        )
    }

    return (
        <>
            <DefaultPageHeader
                title={video?.getName ?? ''}
                buttons={[liveStreamButton]}
                badge={isLive ? <LiveBadge>{t('Live')}</LiveBadge> : null}
            />

            {video ? (
                <DefaultPageTabs
                    tabs={pageTabs(video)}
                    onChange={index => setActiveTabIndex(index)}
                    currentTabIndex={activeTabIndex}
                />
            ) : (
                <VideoSkeleton />
            )}
        </>
    )
})

export {
    VideoPage,
}
