import { OrganizationMemberRoles } from '@tivio/firebase'
import { PatreonUserAuth } from '@tivio/types'

import Logger from '../../logger'
import Member from '../../store/Member'
import { getCurrentOrganizationId, setCurrentOrganizationId } from '../../utils/organization.utils'
import { FirebaseUser, getFirestore, getFunctions, loggerFirestore } from '../app'

import { memberConverter } from './converters'
import { initOrganization } from './organization'
import Organization from '../../store/Organization'


export interface TMemberFirestore {
    uid: string
    fName: string
    lName: string
    avatar?: string
    email: string
    /**
     * Optional Patreon credentials for users that are using "Sign in with Patreon" button to sign in
     */
    patreon?: PatreonUserAuth
}
const logger = new Logger('MemberUtils')

export const getMembersCollection = () => {
    return getFirestore().collection('members').withConverter(memberConverter)
}

export const removePatreonMemberInfo = async (memberId: string) => {
    const memberSnapshot = await getMembersCollection().doc(memberId).get()

    if (!memberSnapshot.exists) {
        throw new Error('User not found!')
    }

    await memberSnapshot.ref.update({
        patreon: null,
    })
}

export const getMemberByUid = async (uid: string, tivioUserId: string, organizations: Organization[]) => {
    try {
        const memberSnapshot = await getMembersCollection().doc(tivioUserId).get()

        if (!memberSnapshot.exists) {
            throw new Error('User not found!')
        }

        let [organization] = organizations

        const currentOrganizationId = getCurrentOrganizationId()

        if (currentOrganizationId) {
            const findOrganization = organizations.find(organization => organization.id === currentOrganizationId)

            if (findOrganization) {
                organization = findOrganization
            }
        } else {
            setCurrentOrganizationId(organization.id)
        }

        const memberData = memberSnapshot.data()! // we know it exists
        const currentOrganization = await initOrganization(organization, uid)
        const role = currentOrganization.firestoreData.members[uid].role

        const member = new Member(memberSnapshot.ref, memberData, organizations, currentOrganization, role)

        if (member.getCurrentRole >= OrganizationMemberRoles.ADMIN) {
            await member.initAdmin()
        }

        return member
    } catch (e) {
        loggerFirestore.error(e)
        throw new Error(e)
    }
}

/**
 * Tries to retrieve custom claims.
 * @param user Instance of Firebase user.
 * @returns Current token claims.
 * @throws Error if failed to retrieve custom claims.
 */
export const getCustomClaims = async (user: FirebaseUser, organizationId: string) => {
    try {
        let token = await user.getIdTokenResult()
        if (!token.claims.tivioUserId || token.claims.organizationId !== organizationId) {
            logger.info('Custom claims not found. Generating new...')
            const setCustomClaims = getFunctions().httpsCallable('setCustomClaims')
            await setCustomClaims({
                organizationId,
            })
            await user.getIdToken(true) // token refresh
            token = await user.getIdTokenResult() // get new token
            if (!token.claims.tivioUserId) {
                throw new Error('Getting custom claims failed. User probably hasn\'t corresponding document in Firestore.')
            }
        }
        logger.info('Custom claims:', token.claims)

        return token.claims
    } catch (e) {
        throw new Error(e)
    }
}
