import { Typography } from '@material-ui/core'
import { CustomerId, VideoType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { validateVideoDistribution } from '../../firebase/firestore/content'
import { getTagByPath } from '../../firebase/firestore/tag'
import { isContentVideo } from '../../utils/content.utils'
import { getTranslation } from '../../utils/translate.utils'
import { Header } from '../common'
import { SwitchWithLoading } from '../common/switchWithLoading'
import { useStore } from '../hooks/dataHooks/useStore'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import type { Tag } from '../../store/Tag'
import type { ContentInterface } from '../../types/content'


interface Props {
    content: ContentInterface
}

export const ContentDistributions = observer(({ content }: Props) => {
    const [t] = useTranslation()
    const { store } = useStore()
    const { confirmAction } = useConfirmAction()
    const { showError } = useAlert()

    const [magentaTvTag, setMagentaTvTag] = React.useState<Tag | undefined>(undefined)
    const [magentaTvSkTag, setMagentaTvSkTag] = React.useState<Tag | undefined>(undefined)
    const [bleskTag, setBleskTag] = React.useState<Tag | undefined>(undefined)

    const dvtvExtraTag = store.globalDistributionTags.find(tag => tag.id === process.env.REACT_APP_DVTV_EXTRA_TAG_ID)
    const isVideo = isContentVideo(content)

    const isDVTV = store?.getMember?.getCurrentOrganization.customerId === CustomerId.DVTV

    const isStarmax = store?.getMember?.getCurrentOrganization.customerId === CustomerId.STARMAX


    let isContentDvtvExtra = false
    let isContentMagentaTv = false
    let isContentMagentaTvSk = false
    let isContentVirtualProgram = false
    let hasContentProductPlacement = false

    if (isVideo) {
        if (dvtvExtraTag !== undefined) {
            isContentDvtvExtra = content.hasTagInLinkedVideos(dvtvExtraTag) || content.hasTag(dvtvExtraTag)
        }

        if (magentaTvTag !== undefined) {
            isContentMagentaTv = content.hasTagInLinkedVideos(magentaTvTag) || content.hasTag(magentaTvTag)
        }

        if (magentaTvSkTag !== undefined) {
            isContentMagentaTvSk = content.hasTagInLinkedVideos(magentaTvSkTag) || content.hasTag(magentaTvSkTag)
        }

        isContentVirtualProgram = content.getType === VideoType.VIRTUAL_PROGRAM
        hasContentProductPlacement = content.hasProductPlacement()
    }

    const handleToggleGlobalDistributionTag = async (id: string, isActive: boolean) => {
        const tag = store.globalDistributionTags.find((tag) => tag.id === id)
        if (tag) {
            await content.toggleDistribution(tag, isActive)
        }
    }

    const isGlobalDistributionTagControlDisabled = (id: string): boolean => {
        switch (id) {
            case process.env.REACT_APP_DVTV_EXTRA_TAG_ID:
                return isContentDvtvExtra
            case process.env.REACT_APP_MAGENTA_TV_TAG_ID:
                return isContentMagentaTv
            case process.env.REACT_APP_MAGENTA_TV_SK_TAG_ID:
                return isContentMagentaTvSk
            default:
                return false
        }
    }

    const displayProductPlacementConfirmAction = async () => {
        if (!hasContentProductPlacement && isVideo) {
            try {
                await validateVideoDistribution(content)

                confirmAction(
                    async () => await content.setProductPlacement(),
                    t('Would you like to mark your video as containing product placement? Beware: this action is irreversable.'),
                    t('Product placement notice'),
                    t('Yes'),
                    t('No'),
                )
            } catch (e) {
                showError(e.errors.join(' '))
            }
        }
    }


    const displayMagentaTvConfirmAction = async () => {
        if (isVideo && magentaTvTag) {
            try {
                await validateVideoDistribution(content)
                confirmAction(
                    async () => {
                        await (content.setShowInMagentaTV && content.setShowInMagentaTV(magentaTvTag))
                    },
                    t('Would you like to mark your video as Magenta TV? Beware: this action is irreversable.'),
                    t('Magenta TV notice'),
                    t('Yes'),
                    t('No'),
                )
            } catch (e) {
                showError(e.errors.join(' '))
            }
        }
    }

    const displayMagentaTvSkConfirmAction = async () => {
        if (isVideo && magentaTvSkTag) {
            try {
                await validateVideoDistribution(content)
                confirmAction(
                    async () => {
                        await (content.setShowInMagentaTV && content.setShowInMagentaTV(magentaTvSkTag))
                    },
                    t('Would you like to mark your video as Magenta TV SK? Beware: this action is irreversable.'),
                    t('Magenta TV notice'),
                    t('Yes'),
                    t('No'),
                )
            } catch (e) {
                showError(e.errors.join(' '))
            }
        }
    }

    const toggleBleskDistribution = async (isChecked: boolean) => {
        if (bleskTag) {
            await (content.setBleskDistribution && content.setBleskDistribution(isChecked, bleskTag))
        }
    }

    useEffect(() => {
        const fetchMagentaTvTag = async () => {
            const tag = await getTagByPath(`tags/${process.env.REACT_APP_MAGENTA_TV_TAG_ID}`)
            setMagentaTvTag(tag)
        }

        const fetchMagentaTvSkTag = async () => {
            const tag = await getTagByPath(`tags/${process.env.REACT_APP_MAGENTA_TV_SK_TAG_ID}`)
            setMagentaTvSkTag(tag)
        }

        const fetchBleskTag = async () => {
            const tag = await getTagByPath(`organizations/${store.getMember?.getCurrentOrganization.id}/tags/${process.env.REACT_APP_BLESK_TAG_ID}`)
            setBleskTag(tag)
        }


        fetchMagentaTvTag()
        fetchMagentaTvSkTag()
        fetchBleskTag()
    }, [])

    return (
        <>
            <Header title={t('Distributions')} />
            <SwitchWithLoading
                label={t('Show in feed')}
                isChecked={content.feedVisible}
                onCheck={async (isActive) => {
                    await content.setIsFeedVisible(isActive)
                }}
            />

            {!isContentVirtualProgram && (
                <SwitchWithLoading
                    label={t('Show in RSS')}
                    isDisabled={content.shownInRss === undefined}
                    isChecked={content.shownInRss === true}
                    onCheck={async (isActive) => {
                        await content.setShowInRss(isActive)
                    }}
                />
            )}

            {store.globalDistributionTags.map((tag) => (
                <SwitchWithLoading
                    key={tag.id}
                    label={getTranslation(tag.name)}
                    isChecked={content.hasDistribution(tag)}
                    isDisabled={isGlobalDistributionTagControlDisabled(tag.id)}
                    onCheck={async (isActive) => {
                        switch (tag.id) {
                            case process.env.REACT_APP_DVTV_EXTRA_TAG_ID:
                                await displayProductPlacementConfirmAction()
                                await handleToggleGlobalDistributionTag(tag.id, isActive)
                                break
                            default:
                                break
                        }
                    }}
                />
            ))}

            {
                isDVTV && magentaTvTag && (
                    <SwitchWithLoading
                        label={getTranslation(magentaTvTag?.name)}
                        isChecked={content.hasDistribution(magentaTvTag)}
                        isDisabled={isGlobalDistributionTagControlDisabled(magentaTvTag.id)}
                        onCheck={async () => await displayMagentaTvConfirmAction()}
                    />
                )
            }

            {
                isDVTV && magentaTvSkTag && (
                    <SwitchWithLoading
                        label={getTranslation(magentaTvSkTag?.name)}
                        isChecked={content.hasDistribution(magentaTvSkTag)}
                        isDisabled={isGlobalDistributionTagControlDisabled(magentaTvSkTag.id)}
                        onCheck={async () => await displayMagentaTvSkConfirmAction()}
                    />
                )
            }

            {
                isStarmax && bleskTag && (
                    <SwitchWithLoading
                        label={getTranslation(bleskTag?.name)}
                        isChecked={content.hasDistribution(bleskTag)}
                        isDisabled={false}
                        onCheck={async (isChecked) => await toggleBleskDistribution(isChecked)}
                    />
                )
            }


            <SwitchWithLoading
                label={t('Product placement')}
                isChecked={hasContentProductPlacement}
                isDisabled={hasContentProductPlacement || isContentDvtvExtra}
                onCheck={async () => await displayProductPlacementConfirmAction()}
            />

            {hasContentProductPlacement && (
                <Typography
                    component="p"
                    color="textSecondary"
                >
                    {t('Video has been marked as containing product placement, which cannot be changed.')}
                </Typography>
            )}

            {(!hasContentProductPlacement && isContentDvtvExtra) && (
                <Typography
                    component="p"
                    color="textSecondary"
                >
                    {t('Video has been marked as not containing product placement, which cannot be changed.')}
                </Typography>
            )}
        </>
    )
})
