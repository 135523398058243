import { notEmptyFilter } from '../../utils/array.utils'
import { getFirestore, getFunctions, loggerFirestore } from '../app'

import type { ConversationDocument } from '@tivio/firebase'
import type { Conversation as ConversationInterface, SendMessageRequest, SendMessageResponse } from '@tivio/types'
import type firebase from 'firebase'


export const subscribeToConversations = (
    participantPath: string,
    conversationFactory: (ref: firebase.firestore.DocumentReference<ConversationDocument>, data: ConversationDocument) => ConversationInterface,
    callback: (conversations: ConversationInterface[]) => void,
) => {
    const firestore = getFirestore()
    const participantRef = firestore.doc(participantPath)

    try {
        const conversationsRef = firestore.collection('conversations').where('participantRefs', 'array-contains', participantRef).orderBy('updatedAt', 'desc')

        loggerFirestore.info('Subscribing to conversations collection.')

        const unsubscribe = conversationsRef.onSnapshot((querySnapshot) => {
            const conversations = querySnapshot.docs
                .map((doc) => {
                    const data = doc.data() as ConversationDocument
                    return data ? conversationFactory(doc.ref as firebase.firestore.DocumentReference<ConversationDocument>, data) : null
                })
                .filter(notEmptyFilter)

            callback(conversations)
        })

        return unsubscribe
    } catch (e) {
        loggerFirestore.error('Failed to subscribe to conversations:', e)
        throw new Error(e)
    }
}

export const sendMessage = async (request: SendMessageRequest): Promise<SendMessageResponse> => {
    try {
        const sendMessage = getFunctions().httpsCallable('sendMessage')
        const response: { data: SendMessageResponse } = await sendMessage(request)

        loggerFirestore.info('Successfully sent message.')
        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to send message:', e)
        throw new Error(e)
    }
}

export const markAsRead = async (messageId: string, organizationId: string, conversationId: string) => {
    try {
        const markAsRead = getFunctions().httpsCallable('markAsRead')
        await markAsRead({ messageId, organizationId, conversationId })

        loggerFirestore.info('Successfully marked message as read.')
    } catch (e) {
        loggerFirestore.error('Failed to mark message as read:', e)
        throw new Error(e)
    }
}
