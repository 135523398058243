import { EncodingProcessPayload, ProcessDocument } from '@tivio/firebase'


export const getMostRecentVideoEncodingProcesses = (encodingProcesses: ProcessDocument<EncodingProcessPayload>[]) => {
    const uniqueVideoQualities: number[] = []

    return encodingProcesses.sort((process, previouseProcess) => (
            previouseProcess.created.toMillis() - process.created.toMillis()
        ))
        .map(process => {
            const videoTrack = process.payload.videoTrack ?? 0
            if (uniqueVideoQualities.indexOf(videoTrack) !== -1) {
                return null
            } else {
                uniqueVideoQualities.push(videoTrack)

                return process
            }
        })
        .filter(process => process !== null)
        .slice(0, uniqueVideoQualities.length)
}