import Forum from '@material-ui/icons/Forum'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { goCommunityPage } from '../../utils/history.utils'
import { useConversations } from '../hooks/dataHooks/useConversations'

import { MenuLink } from './MenuLink'


const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const UnreadBubble = styled.div<{ isVisible: boolean }>`
    border-radius: 13px;
    min-width: 26px;
    padding: 2px 8px;
    margin-left: 8px;
    text-align: center;
    font-weight: bold;
    line-height: 22px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: ${({ isVisible }) => Number(isVisible)};
    transform: scale(${({ isVisible }) => isVisible ? 1 : 0.4}) rotateZ(${({ isVisible }) => isVisible ? 0 : -45}deg);
    transition: opacity 250ms ease-out, transform 200ms ease-in-out;
`

export const MenuCommunityLink = () => {
    const [t] = useTranslation()
    const { notSeenCount } = useConversations()
    return (
        <Wrapper>
            <MenuLink
                Icon={Forum}
                caption={t('Community')}
                onClick={goCommunityPage}
            >
                <UnreadBubble isVisible={notSeenCount > 0}>{notSeenCount}</UnreadBubble>
            </MenuLink>
        </Wrapper>
    )
}
