export enum PAGES {
    USER_NOT_FOUND = '/user-not-found',
    EPG_CHANNEL = '/epg-channel/:epgChannelId',
    SETTINGS = '/settings',
    ORGANIZATION_SETTINGS = '/organization-settings',
    ROOT = '/',
    ANALYTICS = '/analytics',
    DEPLOY = '/deploy',
    LOGIN = '/login',
    SCREENS = '/screens',
    SCREEN = '/screens/:screenId',
    WIDGETS = '/widgets',
    WIDGET = '/widget/:widgetId',
    ADS = '/monetization/ads',
    AD_PROFILE_CREATE = '/monetization/ad-profile/create',
    AD_PROFILE_DETAIL = '/monetization/ad-profile/:adProfileId',
    SUBSCRIPTIONS = '/monetization/subscriptions',
    SUBSCRIPTION = '/monetization/subscriptions/:monetizationId',
    TRANSACTIONS = '/monetization/transactions',
    TRANSACTION = '/monetization/transactions/:monetizationId',
    PROMOTIONS = '/monetization/promotions',
    SECRETS = '/secrets',
    USERS = '/users',
    USER = '/users/:userId',
    USER_EDIT = '/users/:userId/edit',
    VIDEOS = '/videos',
    VIDEO = '/videos/:videoId',
    VIDEO_EDIT = '/videos/:videoId/edit',
    SERVICES = '/services',
    NOTIFICATION = '/services/:notificationId',
    EMBED = '/embed',
    APPLICATIONS = '/applications',
    ARTICLES = '/articles',
    ARTICLE = '/articles/:articleId',
    FEED = '/feed',
    TOS_CONSENT = '/tos-consent',
    SIGNUP = '/signup/:inviteId',
    MULTIUPLOAD = '/multiupload',
    ROADMAP = '/roadmap',
    COMMUNITY = '/community',
    COMMUNITY_MESSAGES = '/community/messages/:conversationId',
}

export enum COLORS {
    PRIMARY = '#0190c8',
    PRIMARY_HOVER = '#4db8e7',
    SECONDARY = '#626878',
    SECONDARY2 = '#808491',
    BACKGROUND = '#000924',
    DRAWER_BACKGROUND = '#0d152f',
    DEFAULT_HOVER = '#D8D8D8',
    DRAWER_BORDER = 'rgba(0, 0, 0, 0.54)',
    MARKER_COLOR = '#697087',
    DANGER_COLOR = '#cd127a',
    TODO1 = '#101831',
    TODO2 = '#838ca7', // silver
    SUCCESS = '#308934',
    SECONDARY2_HOVER = '#fff',
    DISABLED_OVERLAY = 'rgba(0, 0, 0, 0.3)',
    ACTIVE_CHIP = 'rgba(216, 216, 216, 0.25)',
    INACTIVE_CHIP = '#696969',
    ADD_CHIP = 'rgba(255, 255, 255, 0.15)',
    ACTIVE_CHIP_TEXT = '#fff',
    INACTIVE_CHIP_TEXT = 'rgba(255, 255, 255, 0.5)',
    EPG_ITEM_BACKGROUND = '#001C3B',
    EPG_LINE = 'rgba(255, 255, 255, 0.2)',
    EPG_ITEM_TEXT = 'rgba(255, 255, 255, 0.87)',
    EPG_DISABLED = 'rgba(128, 128, 128, 0.2)',
    LIVE_BUTTON = '#ff0061',
    STATUS_DRAFT = 'rgba(255, 255, 255, 0.5)',
    STATUS_UNLISTED = '#e78623',
    STATUS_PUBLISHED = '#ffffff',
}

export enum MARKER_TYPES {
    AD = 'AD',
    INTRO = 'INTRO',
    OUTRO = 'OUTRO',
    CHAPTER = 'CHAPTER',
    EMPTY = 'EMPTY',
    AD_SEGMENT = 'AD_SEGMENT',
    BET = 'BET',
    BREAK = 'BREAK',
    END = 'END',
    START = 'START',
    CUT = 'CUT',
    TRAILER = 'TRAILER',
    CONTINUE = 'CONTINUE',
    SELF_PROMO = 'SELF_PROMO',
    SPONSOR = 'SPONSOR',
    PROGRAM_BREAK = 'PROGRAM_BREAK',
    TASTING = 'TASTING',
}
