import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { useConversations } from '../hooks/dataHooks/useConversations'

import { ConversationLayout } from './ConversationLayout'



export interface ConversationPageProps {
    conversationId: string;
}

const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 300px);
    text-align: center;
    color: ${({ theme }) => theme.palette.text.secondary};
    padding: ${({ theme }) => `${theme.spacing(4)}px`};
`

const EmptyStateImage = styled.img`
    width: 200px;
    height: auto;
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`

const EmptyStateTitle = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
`

const EmptyStateDescription = styled(Typography)`
    font-size: 14px;
`

export const ConversationPage = ({ conversationId }: ConversationPageProps) => {
    const { conversations, participantPath } = useConversations({ conversationId })
    const [t] = useTranslation()

    const renderEmptyState = () => (
        <EmptyStateContainer>
            {false && <EmptyStateImage
                src="/assets/empty-state.svg"
                alt={t('conversations.emptyStateTitle')}
            />}
            <EmptyStateTitle>{t('You don\'t have any conversations yet')}</EmptyStateTitle>
            <EmptyStateDescription>{t('Users can start conversations with you. When they do, you\'ll see them here.')}</EmptyStateDescription>
        </EmptyStateContainer>
    )

    if (!conversations.length) {
        return renderEmptyState()
    }

    return (
        <ConversationLayout
            tabs={[
                {
                    name: t('Messages'),
                },
            ]}
            conversationId={conversationId}
            participantPath={participantPath ?? ''}
            getConversationList={async () => conversations}
        />
    )
}
