import { alpha, Button, TextareaAutosize } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Stack } from '../Stack'

import type { Conversation } from '@tivio/types'


const Container = styled(Stack)<{ isFocused?: boolean; isLoading?: boolean }>`
    position: relative;
`

const StyledTextareaAutosize = styled(TextareaAutosize)`
    background-color: transparent;
    font-family: inherit;
    font-size: 14px;
    padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2.5)}px`};
    color: ${(props) => alpha(props.theme.palette.text.primary, props.disabled ? 0.5 : 1)};
    border: 1px solid rgba(255, 255, 255, 0.45);
    border-radius: 24px;
    resize: none;
    flex: 1;
    transition: border-color 99ms ease-out, box-shadow 99ms ease-out;
    &:focus {
        box-shadow: 0 0 4px 2px rgba(255, 255, 255, 0.18);
        border-color: rgba(255, 255, 255, 0.99);
        outline: none;
    }
`

const SendButton = styled(Button)`
    min-height: 48px;
`

const Error = styled.div`
    color: ${(props) => props.theme.palette.error.main};
    font-size: 12px;
`

export interface Props {
    replyingTo?: string
    isHidden?: boolean
    conversation: Conversation
}

export const AddMessage = ({ isHidden, conversation }: Props) => {
    const [t] = useTranslation()
    const [isLoading, setIsLoading] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState<string>()
    const textareaRef = React.useRef<HTMLTextAreaElement>(null)

    const handleKeyDown = useCallback(
        async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            const textarea = textareaRef.current
            const text = textarea?.value.trim() ?? null
            if (textarea && text && !isLoading && event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()

                setIsLoading(true)
                setErrorMessage(undefined)

                try {
                    await conversation.sendMessage(text)
                    textarea.value = ''
                    // focus back on textarea
                    textarea.focus()
                } catch (error) {
                    setErrorMessage((error as Error).message)
                }

                setIsLoading(false)
            }
        },
        [conversation, isLoading],
    )

    const handleSendMessage = useCallback(async () => {
        const textarea = textareaRef.current
        const text = textarea?.value.trim() ?? null
        if (textarea && text && !isLoading) {
            setIsLoading(true)
            setErrorMessage(undefined)

            try {
                await conversation.sendMessage(text)
                textarea.value = ''
                // focus back on textarea
                textarea.focus()
            } catch (error) {
                setErrorMessage((error as Error).message)
            }

            setIsLoading(false)
        }
    }, [conversation, isLoading])

    // auto focus textarea
    React.useEffect(() => {
        textareaRef.current?.focus()
    }, [conversation.id])

    return (
        <Stack direction="column">
            <Container
                direction="row"
                spacing={1.5}
                alignItems="center"
                isLoading={isLoading}
            >
                <StyledTextareaAutosize
                    ref={textareaRef}
                    className=""
                    placeholder={t('Write a message...')}
                    disabled={isLoading}
                    onKeyDown={handleKeyDown}
                />
                <SendButton
                    size="large"
                    onClick={handleSendMessage}
                    disabled={isLoading}
                >
                    {t('Send')}
                </SendButton>
            </Container>
            {errorMessage && !isHidden && <Error>{errorMessage}</Error>}
        </Stack>
    )
}
