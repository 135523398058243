import { Typography } from '@material-ui/core'
import { generateConsecutiveDates, truncateText } from '@tivio/common'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled, { css } from 'styled-components'

import { PREVIOUS_SCROLL_POSITION_KEY } from '../../../static/constants'
import { COLORS } from '../../../static/enum'
import { useEpgActions } from '../../hooks/dataHooks/useEpgActions'
import { useEpgUI } from '../../hooks/dataHooks/useEpgUI'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { useDialog } from '../../hooks/uiHooks/useDialog'

import { AddEpgScheduleItemDialog } from './AddEpgScheduleItemDialog'
import { AddScheduleItemArea } from './AddScheduleItemArea'
import { EpgScheduleColumnAddArea } from './EpgScheduleColumnAddArea'
import { EpgScheduleItemWithAddArea } from './EpgScheduleItemWithAddArea'
import { ScheduleTimeline, TIMELINE_HEIGHT_PX } from './ScheduleTimeline'

import type { TvProgram } from '../../../store/TvProgram'
import type { TvChannelDocument } from '@tivio/firebase'
import type { Dayjs } from 'dayjs'


const COLUMN_HEADER_DATE_FORMAT = 'ddd DD.MM.YYYY'
const CONTAINER_PADDING_RIGHT_LEFT_PX = 22

/**
 * One timeline per hour
 */
const NUMBER_OF_TIMELINES = 24

const HeaderContainer = styled.div`
    height: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Header = styled(Typography) <{ active?: boolean }>`
    align-self: center;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.87);

    ${props => props.active && css`
        padding: 8px 17px 6px 18px;
        border-radius: 16px;
        background-color: rgba(1, 144, 200, 0.14);
    `}
`

const PaddingLeftRightStyles = css`
    padding-right: ${CONTAINER_PADDING_RIGHT_LEFT_PX}px;
    padding-left: ${CONTAINER_PADDING_RIGHT_LEFT_PX}px;
`

const Container = styled.div<{ heightPx: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${props => props.heightPx}px;
    position: relative;
    ${PaddingLeftRightStyles};

    border-top: solid ${TIMELINE_HEIGHT_PX}px ${COLORS.EPG_LINE};
`

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;

    // right border for all columns apart from the last
    &:not(:last-child) ${Container} {
        border-right: solid ${TIMELINE_HEIGHT_PX}px ${COLORS.EPG_LINE};
    }
`

const StartAddItemArea = styled.div`
    width: 100%;
    position: absolute;
    top: -1px;

    ${PaddingLeftRightStyles};
`

const EpgScheduleItemWithAddAreaStyled = styled(EpgScheduleItemWithAddArea)`
    ${PaddingLeftRightStyles};
`

const DisablePastArea = styled.div<{ height: string }>`
    width: 100%;
    height: ${props => props.height};
    position: absolute;
    top: 0;
    background-color: ${COLORS.EPG_DISABLED};
`

interface Props {
    index: number
    date: Dayjs
    tvChannelId: string
    items: TvProgram[]
    /**
     * If column is active (change style of header)
     */
    active?: boolean
    tvChannel: TvChannelDocument | null
}

/**
 * Represents one column in EPG schedule.
 */
export const EpgScheduleColumn = ({ index, date: originalDate, tvChannelId, items, active = false, tvChannel }: Props) => {
    const date = originalDate.startOf('day')

    const disabledViewRef = useRef<HTMLDivElement | null>(null)

    const [t] = useTranslation()
    const { closeDialog, isDialogOpen, openDialog, data } = useDialog<{ time: Dayjs }>()
    const { addVideoToEpg, deleteItemFromEpg } = useEpgActions()
    const { confirmAction } = useConfirmAction()

    const { convertTimeToPx } = useEpgUI()

    const isPastDate = date.isBefore(dayjs(), 'day')
    const isFutureDate = date.isAfter(dayjs(), 'day')
    const isLocked = tvChannel?.epgLockDurationInMinutes ? date.isBefore(dayjs().add(tvChannel.epgLockDurationInMinutes, 'minutes'), 'day') : false

    const isPastOrTodayDate = !isFutureDate

    const showItemAddAreaOnStart = (() => {
        if (items.length === 0 || isPastOrTodayDate) {
            return false
        }

        const firstItem = items[0]
        const firstItemFromStartsAtMidnight = firstItem.from.minute() === 0 && firstItem.from.hour() === 0

        return firstItem.isOverMidnight || firstItemFromStartsAtMidnight
    })()

    const disablePastAreaHeight = (() => {
        if (isLocked) {
            return '100%'
            // return date.isSame(dayjs().add(EDITABLE_EPG_DAYS_IN_ADVANCE, 'days'), 'day')
            // ? `${(((dayjs().get('hour') * 60 + dayjs().get('minute')) / (24 * 60)) * 100).toFixed(2)}%`
            // : '100%'
        } else if (isFutureDate) {
            return '0'
        } else if (isPastDate) {
            return '100%'
        } else {
            return `${convertTimeToPx(dayjs())}px`
        }
    })()

    const timelineDates = generateConsecutiveDates(NUMBER_OF_TIMELINES, date, 'h')
    const containerHeightPx = convertTimeToPx(date.endOf('day'))


    const onButtonClick = (time: Dayjs) => {
        openDialog({ time })
    }

    const onItemAdd = async (videoPath: string, addItemTime: Dayjs) => {
        await addVideoToEpg(videoPath, addItemTime, tvChannelId)
    }

    const onItemDelete = async (item: TvProgram) => {
        return confirmAction(
            () => deleteItemFromEpg(item, tvChannel?.epgLockDurationInMinutes),
            `${t('Remove item')} ${truncateText(item.name ?? '', 30)}?`,
        )
    }

    useEffect(() => {
        const previousScrollPosition = sessionStorage.getItem(PREVIOUS_SCROLL_POSITION_KEY)

        setTimeout(() => {
            if (previousScrollPosition) {
                window.scrollTo({
                    top: parseInt(previousScrollPosition),
                })
                sessionStorage.removeItem(PREVIOUS_SCROLL_POSITION_KEY)
            } else if (disabledViewRef.current && disabledViewRef.current.clientHeight > 0) {
                // Scroll to current time
                window.scrollTo({
                    top: disabledViewRef.current.clientHeight - window.innerHeight / 4,
                })
            }
        }, 0)
    }, [])

    return (
        <OuterContainer>
            <HeaderContainer>
                <Header active={active}>{date.format(COLUMN_HEADER_DATE_FORMAT)}</Header>
            </HeaderContainer>
            <Container heightPx={containerHeightPx}>
                {showItemAddAreaOnStart && !isLocked && (
                    <StartAddItemArea>
                        <AddScheduleItemArea
                            onButtonClick={() => onButtonClick(date)}
                            height={0}
                        />
                    </StartAddItemArea>
                )}
                {!isPastDate && (
                    <EpgScheduleColumnAddArea
                        onClick={onButtonClick}
                        columnDate={date}
                    />
                )}
                <DisablePastArea
                    ref={disabledViewRef}
                    height={disablePastAreaHeight}
                />
                {timelineDates.map(timelineDate => (
                    <ScheduleTimeline
                        key={timelineDate.toISOString()}
                        time={timelineDate}
                        showText={index === 0}
                    />
                ))}
                {items.length === 0 && isPastDate && <Typography>{t('No programs this day')}</Typography>}
                {items.map((item) => (
                    <EpgScheduleItemWithAddAreaStyled
                        key={item.id}
                        item={item}
                        columnDate={date}
                        onDelete={onItemDelete}
                        onAdd={onButtonClick}
                        isLocked={isLocked}
                    />
                ))}
            </Container>
            {isDialogOpen && (
                <AddEpgScheduleItemDialog
                    defaultTime={data?.time ?? date}
                    open={isDialogOpen}
                    tvChannel={tvChannel}
                    onClose={closeDialog}
                    title={t('Add video to EPG')}
                    onAdd={onItemAdd}
                />
            )}
        </OuterContainer>
    )
}
