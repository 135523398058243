import { MONETIZATION_DURATION, MONETIZATION_FREQUENCY, PurchaseDocumentGateway } from '@tivio/types'
import firebase from 'firebase'

import { getFirestore } from '../../../firebase/app'
import { getCurrency, getPrice } from '../../../utils/monetization.utils'

import type Monetization from '../../../store/Monetization'
import type { DocumentReference, PurchasableMonetizationDocument, PurchaseDocument, VideoDocument } from '@tivio/firebase'
import type { PurchaseStatus } from '@tivio/types'
import type dayjs from 'dayjs'


export function createPurchase({
    userId,
    videoRef,
    monetization,
    status,
    createdBy,
    startDate,
    endDate,
}: {
    userId: string
    videoRef?: DocumentReference<VideoDocument> | null
    monetization: Monetization<PurchasableMonetizationDocument>
    status: PurchaseStatus
    createdBy: string
    startDate?: Date | null
    endDate?: Date | null
}): Promise<void> {
    const now = firebase.firestore.Timestamp.now()
    const newDoc = getFirestore().collection(`users/${userId}/purchases`).doc()
    return newDoc.set({
        documentId: newDoc.id,
        created: startDate ? firebase.firestore.Timestamp.fromDate(startDate) : now,
        expired: endDate ? firebase.firestore.Timestamp.fromDate(endDate) : null,
        updated: now,
        gateway: PurchaseDocumentGateway.tivio,
        monetization: {
            ...(monetization.getDurationDays ? { durationDays: monetization.getDurationDays } : {}),
            currency: getCurrency(monetization),
            price: getPrice(monetization),
            frequency: monetization.getFrequency ?? null,
            title: monetization.getName ?? null,
            type: monetization.getType as 'transaction' | 'subscription' ?? null,
        },
        monetizationRef: monetization.getRef,
        videoRef: videoRef ?? null,
        status,
        createdBy,
    } satisfies PurchaseDocument)
}

export function getDayJsUnitFromFrequency(monetization: Monetization): { count: number, unit: dayjs.ManipulateType } {
    switch (monetization.getFrequency) {
        case MONETIZATION_FREQUENCY.DAILY:
            return { count: 1, unit: 'day' }
        case MONETIZATION_FREQUENCY.WEEKLY:
            return { count: 1, unit: 'week' }
        case MONETIZATION_FREQUENCY.MONTHLY:
            return { count: 1, unit: 'month' }
        case MONETIZATION_FREQUENCY.HALF_ANNUALLY:
            return { count: MONETIZATION_DURATION.HALF_YEAR, unit: 'day' }
        case MONETIZATION_FREQUENCY.ANNUALLY:
            return { count: 1, unit: 'year' }
        default:
            return { count: 0, unit: 'day' }
    }
}
