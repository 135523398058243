import { CachedPagination, Comment, tivio } from '@tivio/core-js'
// TODO: Can't be called from here
import { upsertComment } from '@tivio/firebase'
import { autorun } from 'mobx'
import { useCallback, useEffect, useMemo, useState } from 'react'

import Translator from '../../services/Translator'

import { useError } from './useError'

import type { EditCommentRequest, MessageDocument } from '@tivio/firebase'
import type {
    AddMessageOptions,
    ContentOrConversationId,
    Disposer,
    Message as MessageType,
    PaginationInterface,
    UseMessages,
} from '@tivio/types'


export const useComments: UseMessages = (options) => {
    const { mainParentId, autoInit } = options
    const contentOrConversationId: ContentOrConversationId = useMemo(
        () => ('contentId' in options ? { contentId: options.contentId } : { conversationId: options.conversationId }),
        [options],
    )
    const [pagination, setPagination] = useState<PaginationInterface<MessageType>>({
        fetchMore: () => {},
        items: [],
        hasNextPage: false,
        loading: false,
    })

    const { error, raiseError } = useError()

    useEffect(() => {
        let disposer: Disposer | null = null

        const loadData = () => {
            try {
                tivio.subscribeToContentComments(
                    mainParentId ? { mainParentId } : contentOrConversationId,
                    (pagination: CachedPagination<MessageDocument, Comment>) => {
                        disposer = autorun(() => {
                            setPagination({
                                fetchMore: pagination.fetchMore,
                                items: pagination.items,
                                hasNextPage: pagination.hasNextPage,
                                loading: pagination.loading,
                            })
                        })
                    },
                )
            } catch (error) {
                raiseError(error)
            }
        }

        if (autoInit) {
            loadData()
        }

        return () => {
            if (disposer) {
                disposer()
            }
        }
    }, [contentOrConversationId, mainParentId, autoInit])

    const addMessage = useCallback(
        async (data: AddMessageOptions) => {
            const result = await upsertComment({
                ...data,
                ...(data.parentMessageId && { parentPath: `comments/${data.parentMessageId}` }),
                // TODO: Add support for Articles (contents/...)
                contentPath:
                    'conversationId' in contentOrConversationId
                        ? `conversations/${contentOrConversationId.conversationId}`
                        : `videos/${contentOrConversationId.contentId}`,
            })

            if ('id' in result) {
                return {
                    id: result.id,
                }
            } else {
                return {
                    error: Translator.t('There was an error adding the comment. Please try again.'),
                }
            }
        },
        [contentOrConversationId],
    )

    const editMessage = useCallback(async (data: EditCommentRequest) => {
        const result = await upsertComment(data)

        if ('id' in result) {
            return {
                id: result.id,
            }
        } else {
            return {
                error: Translator.t('There was an error editing the comment. Please try again.'),
            }
        }
    }, [])

    const deleteMessage = useCallback(async (messageId: string) => {
        const result = await upsertComment({
            messageId,
            isDeleted: true,
        })

        if ('deleted' in result) {
            return {
                deleted: true,
            }
        } else {
            return {
                error: Translator.t('There was an error deleting the comment. Please try again.'),
            }
        }
    }, [])

    return {
        pagination,
        error,
        addMessage,
        editMessage,
        deleteMessage,
    }
}
