import {
    Box,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ClearIcon from '@material-ui/icons/Clear'
import { DateTimePicker } from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { ContentInterface } from '../../types/content'
import { cancelTask, reScheduleTask, scheduleTask, ScheduleTaskData, subscribeToTasksByContentRef } from '../../firebase/firestore/tasks'
import { firebaseTimestampFromDate, getFirestore } from '../../firebase/app'
import { TaskPayloadMap, TaskQueue, TaskStatus, TaskType } from '@tivio/firebase'
import { PublishedStatus } from '@tivio/types'
import { Task } from '../../store/Task'
import { alertError, alertSuccess } from '../../utils/alert.utils'


const DatePickerContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
`

interface Props {
    content: ContentInterface,
    disabled?: boolean
    isVideo?: boolean
}

export const ContentSchedulePublish: React.FC<Props> = observer(({ content, disabled = false, isVideo = false }) => {
    const [t] = useTranslation()
    const [date, setDate] = useState<Dayjs | null>(null)
    const [task, setTask] = useState<Task | null>(null)
    const [loading, setLoading] = useState(false)
    const collection = isVideo ? 'videos' : 'content'

    useEffect(() => {
        const contentRef = getFirestore().collection(collection).doc(content.id)
        // follow the task that is scheduled to sync with UI
        const unsubscribe = subscribeToTasksByContentRef<TaskType.DB_UPDATE>(contentRef, (tasks) => {
            const foundTask = tasks.find(task => 
                task.type === TaskType.DB_UPDATE &&
                task.googleTaskId &&
                task.scheduledTime.seconds > dayjs().unix() &&
                task.status === TaskStatus.WAITING &&
                'data' in task.payload && 
                'publishedStatus' in (task.payload as TaskPayloadMap[TaskType.DB_UPDATE]).data
            )
        
            setTask(foundTask || null)
            if (foundTask) {
                setDate(dayjs(foundTask.scheduledTime.toDate()))
            } else {
                setDate(null)
            }
        })

        return () => {
            unsubscribe()
        }
    }, [content.id, collection])


    const handleClearFromClick = async (evt: React.MouseEvent<HTMLButtonElement>) => {
        setLoading(true)
        setDate(null)
        evt.stopPropagation()
        // if task is not null, that means it's an existing scheduling, so we cancel the task
        if (task) {
            await cancelTask(task.id)
            alertSuccess(t('Publishing cancelled successfully'))

        }
        setLoading(false)
    }


    const handleChange = async (date: Dayjs | null) => {
        if (date && date.isBefore(dayjs())) {
            alertError(t('Date cannot be in the past'))
            return
        }
        setLoading(true)
        setDate(date)
        try {
            // if date is null, we do nothing
            if (!date) { 
                return
            }
            // if task is null, that means it's a new scheduling, so we create a new task
            if (!task ) {
                const newTask: ScheduleTaskData<TaskType.DB_UPDATE> = {
                    queue: TaskQueue.VIDEOS,
                    type: TaskType.DB_UPDATE,
                    scheduledTime: firebaseTimestampFromDate(date.toDate()),
                    payload: {
                        collection,
                        id: content.id,
                        data: {
                            publishedStatus: PublishedStatus.PUBLISHED
                        }
                    }
                    
                }
                await scheduleTask(newTask)
                alertSuccess(t('Publishing scheduled successfully'))
            }
            // if task is not null, that means it's an existing scheduling, so we re-schedule the task
            if (task) {
                await reScheduleTask(task.id, firebaseTimestampFromDate(new Date(date.toDate())))
                alertSuccess(t('Publishing re-scheduled successfully'))
            }
        } catch (error) {
            alertError(t(`Failed to schedule publishing with error: ${error}.`,))

            console.error('handleChange error', error)
        } finally {
            setLoading(false)
        }
    }

    if (content.getPublishedStatus === PublishedStatus.PUBLISHED) {
        return null
    }

    return (
        <>
            <Box mb={3}>
                <Typography variant="h6">{t('Schedule Publish')}</Typography>
                <Typography
                    component="p"
                    color="textSecondary"
                >
                    {t('Publishing can be scheduled to a specific date and time. At the scheduled time, the content will be published.')}
                </Typography>
                <DatePickerContainer my={2}>
                    <DateTimePicker
                        value={date}
                        onChange={handleChange}
                        showTodayButton
                        variant="dialog"
                        label={t('Will be published at')}
                        inputVariant="outlined"
                        disabled={disabled || loading}
                        minDate={dayjs()}
                        InputProps={{
                            startAdornment: !disabled && <InputAdornment position="start"><CalendarTodayIcon/></InputAdornment>,
                            endAdornment: !disabled && (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={handleClearFromClick}
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </DatePickerContainer>
            </Box>
        </>
    )
})
