import { createLogger } from '@tivio/common'
import { useEffect, useState } from 'react'

import { getFirestore } from '../../../firebase/app'
import { useError } from '../uiHooks/useError'

import type Video from '../../../store/Video'
import type { DocumentReference, EncodingProcessPayload, ProcessDocument } from '@tivio/firebase'
import type { Dispatch, SetStateAction } from 'react'




const logger = createLogger('useProcesses')

const useEncodingProcesses = (video: Video) => {
    const [encodingProcesses, setEncodingProcesses] = useState<ProcessDocument<EncodingProcessPayload>[] | null>(null)
    const [encodingProcessesCompleted, setEncodingProcessesCompleted] = useState<boolean>(false)
    const { error, raiseError } = useError()

    const getProcessesQuery = async () => {
        const firestore = getFirestore()

        return firestore.collection('processes')
            .where('payload.contentRef', '==', video.ref)
    }

    const setProcessesState = async () => {
        const processesQuery = await getProcessesQuery()

        return processesQuery.onSnapshot(querySnapshot => {
            const processesDocuments = querySnapshot
                .docs
                .map(processDocument => processDocument.data() as ProcessDocument<EncodingProcessPayload>)
                .filter(processDocument => processDocument !== null)

            if (processesDocuments.length > 0) {
                setEncodingProcesses(processesDocuments)
            }
        })
    }

    useEffect(() => {
        (async () => {
            try {
                await setProcessesState()
            } catch (e) {
                raiseError('failed-to-find-processes')
                logger.error(e)
            }
        })()
    },
    [video.isTranscoding])

    useEffect(() => {
        if (encodingProcesses !== null) {
            setEncodingProcessesCompleted(
                encodingProcesses.every(encodingProcess => encodingProcess.completed !== undefined),
            )
        }
    }, [encodingProcesses])

    return {
        error,
        encodingProcesses,
        encodingProcessesCompleted,
    }
}


const getVideoProcesses = async (videoRef: DocumentReference, profileRef: DocumentReference) => {
    const firestore = getFirestore()
    const processesQuery = firestore.collection('processes')
        .where('payload.contentRef', '==', videoRef)
        .where('payload.profileRef', '==', profileRef)
        .where('status', 'in', ['IN_PROGRESS', 'WAITING'])
        .orderBy('created', 'desc')

    const querySnapshot = await processesQuery.get()
    const processesDocuments = querySnapshot
        .docs
        .map(processDocument => processDocument.data() as ProcessDocument<EncodingProcessPayload>)

    return processesDocuments
}



const listenToEncodingProgress = (
    videoRef: DocumentReference,
    profileRef: DocumentReference,
    onProgressUpdate: Dispatch<SetStateAction<number | null>>,
) => {
    const firestore = getFirestore()
    const unSubscribe = firestore.collection('processes')
        .where('payload.contentRef', '==', videoRef)
        .where('payload.profileRef', '==', profileRef)
        .where('status', 'in', ['IN_PROGRESS', 'WAITING'])
        .orderBy('created', 'desc')
        .limit(1)
        .onSnapshot({
            next: (snapshots) => {
                snapshots.docs.map(doc => {
                    const processData = doc.data() as ProcessDocument<EncodingProcessPayload>
                    onProgressUpdate(processData?.progress ?? null)
                })
            },
        })

    return unSubscribe
}

export {
    getVideoProcesses,
    useEncodingProcesses,
    listenToEncodingProgress,
}
