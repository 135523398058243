import React from 'react'
import styled from 'styled-components'

// import { TabButton } from '../../../../../common/components/tivio-pro/common/Tabs'

import { Chat } from './Chat/Chat'
import { ConversationList } from './ConversationList'

import type { Conversation } from '@tivio/types'


const LayoutContainer = styled.div`
    position: relative;
    display: grid;

    ${({ theme }) => theme.breakpoints.up('md')} {
        max-width: calc(1100px + 32px * 2);
    }

    grid-template-columns: minmax(0, 1fr);

    ${({ theme }) => theme.breakpoints.up('md')} {
        grid-template-columns: 350px minmax(0, 1fr);
    }

    margin: 0 auto;

    ${({ theme }) => theme.breakpoints.up('md')} {
        margin: 43px auto 0;
    }

    padding: 0 16px;

    ${({ theme }) => theme.breakpoints.up('md')} {
        padding: 0 32px;
    }

    grid-column-gap: 32px;
`

interface TabItemProps {
    name: string
}

interface Props {
    tabs: TabItemProps[]
    participantPath: string
    conversationId?: string
    getConversationList: () => Promise<Conversation[]>
}

export const ConversationLayout = ({ conversationId, participantPath, getConversationList }: Props) => {
    // const [selectedTab, setSelectedTab] = React.useState(tabs[0].name)
    const [conversationList, setConversationList] = React.useState<Conversation[]>([])
    const [selectedConversation, setSelectedConversation] = React.useState<Conversation | null>(null)

    React.useEffect(() => {
        getConversationList().then((list) => {
            setConversationList(list)
            const found = list.find((item) => item.id === conversationId)
            if (found) {
                setSelectedConversation(found)
            }
        })
    }, [conversationId, getConversationList])

    return (
        <LayoutContainer>
            <div>
                {/* {tabs.map((tab) => (
                    <TabButton
                        key={tab.name}
                        isSelected={selectedTab === tab.name}
                        onClick={() => setSelectedTab(tab.name)}
                    >
                        {tab.name}
                    </TabButton>
                ))} */}
                <ConversationList
                    activeConversationId={selectedConversation?.id}
                    conversations={conversationList}
                />
            </div>
            {selectedConversation && <Chat
                conversation={selectedConversation}
                userPath={participantPath}
            />}
        </LayoutContainer>
    )
}
