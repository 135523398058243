import { Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Stack } from '../../Stack'

import type { MessageUser } from '@tivio/types'


dayjs.extend(relativeTime)

interface Props {
    user: MessageUser
    createdAt: Date | null
    editedAt: Date | null
}

export const Header = ({ user, createdAt, editedAt }: Props) => {
    const [t] = useTranslation()
    return (
        <Stack
            direction="row"
            spacing={1.25}
        >
            <Typography style={{ fontSize: 12, fontWeight: 600 }}>{user.name || user.email}</Typography>
            <Typography style={{ fontSize: 12, fontWeight: 600, opacity: 0.5 }}>
                {createdAt ? dayjs(createdAt).fromNow() : ''}
                {editedAt && ` (${t('edited')})`}
            </Typography>
        </Stack>
    )
}
