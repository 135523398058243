import { createLogger } from '@tivio/common'
import { ALGOLIA_INDEX_NAME, PublishedStatus } from '@tivio/types'

import { getTagById } from '../firebase/firestore/tag'

import type Organization from '../store/Organization'
import type { Tag } from '../store/Tag'
import type TvChannel from '../store/TvChannel'
import type Video from '../store/Video'
import type { PaginationInterface, PaginationOptions } from '@tivio/types'


const logger = createLogger('useSearch')

export type UseSearchOptions = PaginationOptions & Partial<{
    /**
     * The minimum number of characters a query must contain.
     */
    minQueryLength: number
    /**
     * If true, search will return all results as long as the query is considered empty.
     */
    emptyQuery: boolean
    /**
     * First query to use when the hook is initialized.
     */
    defaultQuery: string
}>

export interface UseSearchResult<T> {
    search: (query: string, forceRefresh?: boolean) => void
    pagination: PaginationInterface<T> | null
    error: Error | null
    isLoading: boolean
    isEmptyResult: boolean
    lastQuery: string
}

export const DEFAULT_SEARCH_OPTIONS = {
    /**
     * The maximum number of items to retrieve in one request.
     */
    limit: 5,
    minQueryLength: 3,
    emptyQuery: true,
    defaultQuery: '',
} satisfies UseSearchOptions

export type SearchResultAsStore<T extends ALGOLIA_INDEX_NAME> =
    T extends ALGOLIA_INDEX_NAME.VIDEOS ? Video :
    T extends ALGOLIA_INDEX_NAME.VIDEOSDESC ? Video :
    T extends ALGOLIA_INDEX_NAME.TAGS ? Tag :
    T extends ALGOLIA_INDEX_NAME.TV_CHANNELS ? TvChannel :
    unknown

export async function getUseSearchResults<T extends ALGOLIA_INDEX_NAME>(
    objectIDs: string[],
    indexName: T,
    organization: Organization,
): Promise<SearchResultAsStore<T>[]> {
    // TODO batch get will be nice ('in' query with ids, 10 per batch)
    const nullableResults = await Promise.all(objectIDs.map(objectID => {
        switch (indexName) {
            case ALGOLIA_INDEX_NAME.VIDEOS:
            case ALGOLIA_INDEX_NAME.VIDEOSDESC:
            case ALGOLIA_INDEX_NAME.VIDEOS_FOR_EPG:
                return organization.getVideoById(objectID)
            case ALGOLIA_INDEX_NAME.TAGS:
                return getTagById(organization.id, objectID)
            case ALGOLIA_INDEX_NAME.TV_CHANNELS: // TODO: not implemented yet
            default:
                logger.warn(`Unexpected UseSearchIndices value ${indexName}. Result wouldn't be mapped.`)
                return Promise.resolve(null)
        }
    }))

    return nullableResults.filter(result => !!result) as SearchResultAsStore<T>[]
}

export const getFilters = (organization: Organization): string => {
    return `organizationPath:${organization.path}`
}

export const getSharedOrganizationFilters = (sharedOrganization: Organization): string => {
    return `sharedOrganizationPaths:${sharedOrganization.path}`
}

export const getExternalAndOrganizationFilters = (organization: Organization): string => {
    return `sharedOrganizationPaths:${organization.path} OR organizationPath:${organization.path}`
}

export const getEpgAccessTagFilters = (organization: Organization): string => {
    const filterParts: string[] = [
        `publishedStatus:${PublishedStatus.PUBLISHED} OR publishedStatus:${PublishedStatus.UNLISTED}`,
    ]
    const epgChannelAccessTag = organization.epgChannelAccessTag
    if (epgChannelAccessTag) {
        filterParts.push(`tagPaths:${epgChannelAccessTag.getRef.path}`)
    } else {
        filterParts.push(getExternalAndOrganizationFilters(organization))
    }

    return filterParts.join(' AND ')
}
