import { CustomerId, PublishedStatus, VideoType } from '@tivio/types'

import { createTranslation } from '../creator/translation.creator'
import { firebaseTimestampFromDate } from '../firebase/app'

import { translate } from './translate.utils'

import type { ElementInterface } from '../components/section/SectionElementInterface'
import type { VideoInterface } from '../components/section/SectionVideoEditor'
import type Organization from '../store/Organization'
import type Video from '../store/Video'
import type { VideoDocument } from '@tivio/firebase'


interface VideoInfo {
    name: string,
    duration: number,
}

export const getVideoName = (file: File) => {
    const [_, fileFormat] = file.type.split('/')

    let videoName = file.name

    if (fileFormat) {
        const regexp = new RegExp('\\.' + fileFormat + '$')
        videoName = videoName.replace(regexp, '')
    }

    return videoName
}

const getVideoInfo = async (file: File): Promise<VideoInfo> => {
    // TODO: This should be Translation object
    const name = getVideoName(file)

    const videoElement = document.createElement('video')

    videoElement.preload = 'metadata'
    videoElement.src = URL.createObjectURL(file)

    return new Promise(
        (resolve) => {
            videoElement.onerror = () => {
                resolve({
                    name,
                    duration: 0,
                })
            }

            videoElement.onloadedmetadata = () => {
                window.URL.revokeObjectURL(videoElement.src)
                resolve({
                    name,
                    duration: Math.floor(videoElement.duration * 1000),
                })
            }
        },
    )
}

const VideoToVideoInterface = (video: Video): VideoInterface => {
    return {
        type: VideoType.VIDEO,
        videoInstance: video,
        id: video.id,
        cover: video.getCover,
        hide: video.hide,
        created: firebaseTimestampFromDate(video.getCreated),
        description: video.getDescription,
        name: video.getName,
        defaultName: video.getDefaultName,
        publishedStatus: video.getPublishedStatus,
        organizationRef: video.organization.ref,
    }
}

const NewPlaylistInterface = (playlist: Video): ElementInterface => {
    return {
        id: playlist.id,
        name: playlist.getName,
        defaultName: playlist.getDefaultName,
        created: firebaseTimestampFromDate(playlist.getCreated),
        hide: playlist.hide,
        description: playlist.getDescription,
        cover: playlist.getCover,
        publishedStatus: playlist.getPublishedStatus,
        type: VideoType.PLAYLIST,
        organizationRef: playlist.organization.ref,
    }
}

// TODO: Move to video.creator.ts?
const createVideoData = (organization: Organization): VideoDocument => {
    return {
        type: VideoType.VIDEO,
        created: firebaseTimestampFromDate(),
        name: createTranslation('Video name', organization.languages),
        defaultName: translate('Video name'),
        description: createTranslation('', organization.languages),
        tags: [],
        hide: false,
        publishedStatus: PublishedStatus.DRAFT,
        monetizations: [],
        assets: {},
        organizationRef: organization.ref,
    }
}

export const composeIframeCode = (videoId: string, customerId?: CustomerId) => {
    let cncIframeMarkup = '<div id="tivio-embedded-player-container"></div>\n'
    cncIframeMarkup += '<script id="tivio-embedded-player-script" type="text/javascript"'
    cncIframeMarkup += ` src="https://embedded-player.tiv.io/embedPlayer.js?videoId=${videoId}"></script>`

    let defaultIframeMarkup = '<iframe\n'
    defaultIframeMarkup += ' width="560"\n'
    defaultIframeMarkup += ' height="315"\n'
    defaultIframeMarkup += ` src="https://embedded-player.tiv.io/?videoId=${videoId}"\n`
    defaultIframeMarkup += ' title="Tivio video player"\n'
    defaultIframeMarkup += ' allow="autoplay;"\n'
    defaultIframeMarkup += ' allowfullscreen></iframe>\n'

    if (customerId === CustomerId.CNC) {
        return cncIframeMarkup
    } else {
        return defaultIframeMarkup
    }
}

export {
    VideoToVideoInterface,
    NewPlaylistInterface,
    getVideoInfo,
    createVideoData,
}
