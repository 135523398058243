import type { Tag } from '../store/Tag'
import type Video from '../store/Video'
import type { AssetsField } from '@tivio/types'


export const getVideoCoverAsset = (assets: AssetsField) => {
    const asset = (
        assets['cover']?.['@1'] ??
        assets['cover']?.['@2'] ??
        assets['cover']?.['@3']
    )

    return asset?.background
}

export const hasOktagonLiveTag = (video: Video) => {
    // TIV-1430 TODO remove hardcoded ID!
    // This is an id of "Live stream" tag (organization Oktagon, tivio-production)
    return video?.tags.some((tag) => {
        return tag.id === 'WFW0SHuil6BKndZm6uAx'
    })
}

export const isItemVideo = (item: Video | Tag): item is Video => {
    return (item as Video).type === 'video'
}