import {
    ClickAwayListener,
    Divider,
    Grow,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    TextField,
} from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import { Highlight, useFuzzySearchList } from '@nozbe/microfuzz/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Logger from '../../logger'
import { lightTheme } from '../../static/theme'
import store from '../../store'
import { goLoginPage } from '../../utils/history.utils'
import AppLoading from '../AppLoading'

import type Member from '../../store/Member'
import type Organization from '../../store/Organization'
import type { Theme } from '@material-ui/core/styles'
import type { MutableRefObject } from 'react'


interface TProps {
    open: boolean
    member: Member
    organization: Organization
    onClose: () => void
    anchorRef: MutableRefObject<HTMLButtonElement | null>
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    popover: {
        zIndex: 1000,
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    paper: {
        width: 230,
        marginTop: theme.spacing(2),
    },
    listItemIcon: {
        minWidth: 36,
    },
}))

const logger = new Logger('MenuOrganizationsPopup')

const MenuOrganizationsPopup = (props: TProps) => {
    const { anchorRef, open } = props
    const { logout, switchOrganization } = store
    const paperRef = React.useRef<HTMLDivElement>(null)
    const [t] = useTranslation()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const filteredList = useFuzzySearchList({
        list: props.member.getOrganizations,
        queryText: search,
        getText: (organization) => [organization.name],
        mapResultItem: ({ item, score, matches: [highlightRanges] }) => ({ item, score, highlightRanges }),
    })
    const classes = useStyles()

    const handleClose = () => {
        props.onClose()
    }

    const handleLogout = async () => {
        try {
            await logout()
            goLoginPage()
        } catch (e) {
            logger.error(e)
        }
    }

    const handleSwitchOrganization = async (organization: Organization) => {
        if (organization.id !== props.member.getCurrentOrganization.id) {
            setLoading(true)
            handleClose()

            try {
                await switchOrganization(organization)
                setSearch('')
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }
    }

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prev) => (prev < filteredList.length - 1 ? prev + 1 : prev))
                event.preventDefault()
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev))
                event.preventDefault()
            } else if (event.key === 'Enter' && selectedIndex >= 0) {
                const selectedItem = filteredList[selectedIndex]?.item
                if (selectedItem) {
                    handleSwitchOrganization(selectedItem)
                }
                event.preventDefault()
            } else if (event.key === 'Escape') {
                if (search.length > 0) {
                    setSearch('')
                } else {
                    handleClose()
                }
            }
        },
        [filteredList, selectedIndex],
    )

    useEffect(() => {
        setSelectedIndex(Math.min(selectedIndex, filteredList.length - 1))
    }, [search, filteredList, selectedIndex])

    useEffect(() => {
        const element = paperRef.current
        if (open && element) {
            element.scrollTo(0, 0)
        }
    }, [open])

    const renderSearch = () => {
        return (
            <ListItem>
                <TextField
                    autoFocus
                    placeholder={t('Search')}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
            </ListItem>
        )
    }

    const renderOrganizations = () => {
        return filteredList.map(({ item, highlightRanges }, index) => (
            <ListItem
                onClick={() => handleSwitchOrganization(item)}
                key={item.id}
                button
                selected={index === selectedIndex}
            >
                <ListItemIcon>{props.organization.id === item.id && <Check color="primary" />}</ListItemIcon>
                <ListItemText>
                    <Highlight
                        text={item.name}
                        ranges={highlightRanges}
                    />
                </ListItemText>
            </ListItem>
        ))
    }

    const renderLogout = () => {
        return (
            <ListItem
                onClick={handleLogout}
                button
            >
                <ListItemIcon>
                    <PowerSettingsNew />
                </ListItemIcon>
                <ListItemText>{t('Logout')}</ListItemText>
            </ListItem>
        )
    }

    return (
        <ThemeProvider theme={lightTheme}>
            {loading && <AppLoading />}
            <div
                className={classes.root}
                data-e2e="menu-organizations-popup"
            >
                <Popper
                    ref={paperRef}
                    className={classes.popover}
                    anchorEl={anchorRef.current}
                    disablePortal
                    open={open}
                    role={undefined}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <List>
                                        {props.member.getOrganizations.length > 1 && renderSearch()}
                                        {renderOrganizations()}
                                        <Divider />
                                        {renderLogout()}
                                    </List>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ThemeProvider>
    )
}

export default MenuOrganizationsPopup
