import { useEffect, useMemo, useState } from 'react'

import { getFirestore } from '../../../firebase/app'
import Logger from '../../../logger'
import { Conversation } from '../../../store/Conversation'

import { useOrganization } from './useOrganization'

import type { ConversationDocument } from '@tivio/firebase'
import type { Disposer } from '@tivio/types'
import type firebase from 'firebase'


interface Options {
    conversationId?: string
    shouldSubscribeToConversations?: boolean
}

const logger = new Logger('useConversations')

export const useConversations = ({ conversationId, shouldSubscribeToConversations = true }: Options = {}) => {
    const { organization } = useOrganization()
    const participantPath = organization?.path
    const [conversations, setConversations] = useState<Conversation[]>([])

    useEffect(() => {
        let disposer: Disposer | undefined

        const subscribeToConversationsInner = () => {
            if (!participantPath) {
                logger.error('User not logged in')
                return
            }

            const firestore = getFirestore()
            const participantRef = firestore.doc(participantPath)

            try {
                const conversationsRef = firestore
                    .collection('conversations')
                    .where('participantRefs', 'array-contains', participantRef)
                    .orderBy('updatedAt', 'desc')

                logger.info('Subscribing to conversations collection.')

                disposer = conversationsRef.onSnapshot((querySnapshot) => {
                    setConversations((prevConversations) => {
                        const updatedConversations = querySnapshot.docs.map((doc) => {
                            const data = doc.data() as ConversationDocument
                            const existingConversation = prevConversations.find((c) => c.id === doc.id)

                            if (existingConversation) {
                                existingConversation.setData(data)
                                return existingConversation
                            }

                            return new Conversation(doc.ref as firebase.firestore.DocumentReference<ConversationDocument>, data)
                        })

                        updatedConversations.sort((a, b) => (b.updatedAt.getTime() > a.updatedAt.getTime() ? 1 : -1))

                        return updatedConversations
                    })
                })
            } catch (e) {
                logger.error('Failed to subscribe to conversations:', e)
                throw new Error(e)
            }
        }

        if (shouldSubscribeToConversations) {
            subscribeToConversationsInner()
        }

        return () => {
            disposer?.()
        }
    }, [conversationId, participantPath, shouldSubscribeToConversations])

    const notSeenCount = useMemo(() => conversations.reduce((acc, conversation) => acc + conversation.notSeenCount, 0), [conversations])

    return {
        conversations,
        participantPath,
        notSeenCount,
    }
}
