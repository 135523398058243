import { computed, get, makeObservable, observable, runInAction } from 'mobx'

import type { DocumentReference, OrganizationDocument, UserDocument } from '@tivio/firebase'


type ParticipantDocument = UserDocument | OrganizationDocument;

export interface MessageParticipantOptions {
    name?: string;
    avatarUrl?: string;
}

export class MessageParticipant {
    private _isOrganization: boolean

    constructor(private _ref: DocumentReference<ParticipantDocument>, private _data?: ParticipantDocument, private _options?: MessageParticipantOptions) {
        this._isOrganization = _ref.path.includes('organizations/')

        makeObservable<MessageParticipant, '_isOrganization' | '_data'>(this, {
            _data: observable,
            _isOrganization: observable,
            avatarUrl: computed,
            name: computed,
            email: computed,
        })
    }

    setData(data: ParticipantDocument) {
        runInAction(() => {
            this._data = data
        })
    }

    get path(): string {
        return this._ref.path
    }

    get name(): string {
        return this._data?.name ?? this._options?.name ?? ''
    }

    get email(): string {
        if (typeof this._data === 'object' && 'email' in this._data) {
        return this._data?.email ?? ''
        }
        return ''
    }

    get avatarUrl(): string {
        if (this._isOrganization) {
            const orgData = this._data as OrganizationDocument | undefined
            return orgData?.assets?.profile_photo?.['@1']?.background ?? this._options?.avatarUrl ?? ''
        }
        return this._options?.avatarUrl ?? ''
    }

    get isLoaded(): boolean {
        return !!this.name && !!this.avatarUrl
    }
}
