import { createLogger, escapeDocPath } from '@tivio/common'
import { computed, makeObservable, observable, runInAction } from 'mobx'

import { getFirestore } from '../firebase/app'
import { markAsRead, sendMessage } from '../firebase/firestore/community'

import { Message } from './Message'
import { participantStore } from './ParticipantStore'
import store from './store'

import type { MessageParticipant } from './MessageParticipant'
import type { ConversationDocument, DocumentReference, MessageDocument } from '@tivio/firebase'
import type { Conversation as ConversationInterface, ConversationLastSeen, Disposer } from '@tivio/types'


type DisposerKeys = 'messages'

const logger = createLogger('Conversation')
export class Conversation implements ConversationInterface {
    private _messages = observable.array<Message>([], { deep: false })
    private _lastMessage: Message | null = null
    private _participantsMap = observable.map<string, MessageParticipant>()
    private _isSending = false
    private _disposers: { [key in DisposerKeys]?: Disposer } = {}
    private _messageToMarkAsRead: Message | null = null
    private _markAsReadTimeout: number | null = null

    constructor(
        private _ref: DocumentReference<ConversationDocument>,
        private _data: ConversationDocument,
        private _logger = createLogger('Conversation'),
        loadMessages = false,
    ) {
        if (loadMessages) {
            this.subscribeToMessages()
        }

        this.initParticipants()
        this.initLastMessage()

        makeObservable<Conversation, '_data' | '_isSending' | '_lastMessage' | '_messages'>(this, {
            _data: observable,
            _isSending: observable,
            _lastMessage: observable,
            _messages: observable,
            id: computed,
            createdAt: computed,
            updatedAt: computed,
            createdBy: computed,
            isSending: computed,
            lastMessage: computed,
            lastSeen: computed,
            participants: computed,
            messages: computed,
            isLoading: computed,
        })
    }

    private initLastMessage() {
        const lastMessage = this._data?.lastMessage
        if (lastMessage) {
            runInAction(() => {
                this._lastMessage = new Message(lastMessage.ref, {
                    contentRef: this._ref,
                    text: lastMessage.text,
                    createdAt: this._data.updatedAt ?? this._data.createdAt,
                    parentRef: null,
                    mainParentRef: null,
                    editedAt: null,
                    deletedAt: null,
                    fromUserRef: lastMessage.fromUserRef,
                })
            })
        }
    }

    private initParticipants() {
        for (const participantRef of this._data?.participantRefs ?? []) {
            const participant = participantStore.getParticipant(participantRef)
            runInAction(() => {
                this._participantsMap.set(participantRef.path, participant)
            })
        }
    }

    setData(data: ConversationDocument) {
        runInAction(() => {
            this._data = data
        })
        this.initLastMessage()
    }

    get id(): string {
        return this._ref.id
    }

    get createdAt(): Date {
        return this._data?.createdAt?.toDate() ?? new Date()
    }

    get updatedAt(): Date {
        return this._data?.updatedAt?.toDate() ?? new Date()
    }

    get createdBy(): MessageParticipant | null {
        if (!this._data?.createdByRef) {
            return null
        }
        return this._participantsMap.get(this._data.createdByRef.path) ?? null
    }

    get isSending(): boolean {
        return this._isSending
    }

    get lastMessage(): Message | null {
        return this._lastMessage
    }

    get lastSeen() {
        const lastSeen = this._data?.lastSeen
        if (!lastSeen) {
            return {}
        }

        return Object.entries(lastSeen).reduce<{ [key: string]: ConversationLastSeen }>((result, [key, value]) => {
            if (!value) {
                return result
            }
            const { messageRef, timestamp } = value
            const message = messageRef && this.messages.find((message) => message.id === messageRef.id)
            if (!message) {
                return result
            }

            result[key] = {
                message,
                date: timestamp?.toDate() ?? new Date(),
            }
            return result
        }, {})
    }

    get participants(): MessageParticipant[] {
        return Array.from(this._participantsMap.values()) ?? []
    }

    get title() {
        return this.participants
            .reduce<{ name: string; path: string }[]>((acc, participant) => {
                if (participant.path !== this.organization.path) {
                    acc.push({ name: participant.name, path: participant.path })
                }
                return acc
            }, [])
            .sort((a) => (a.path === this.lastMessage?.participant.path ? -1 : 1))
            .map((participant) => participant.name)
            .join(', ')
    }

    get messages(): Message[] {
        return this._messages
    }

    get organization() {
        const organization = store.getMember?.getCurrentOrganization
        if (!organization) {
            throw new Error('No organization found for current user')
        }
        return organization
    }

    get isLoaded(): boolean {
        return !!this._data
    }

    get isLoading(): boolean {
        return !this.isLoaded || this.participants.some((participant) => !participant.isLoaded)
    }

    get notSeenCount() {
        return this._data?.lastSeen?.[escapeDocPath(this.organization.path)]?.notSeenCount ?? 0
    }

    async subscribeToMessages() {
        this._logger.info('Start subscribing to messages collection group')

        const firestore = getFirestore()
        const query = firestore.collection('messages').where('contentRef', '==', this._ref).orderBy('createdAt', 'asc')

        const unsubscribe = query.onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                const messageData = doc.data() as MessageDocument
                const messageRef = doc.ref as DocumentReference<MessageDocument>

                if (!this._messages.find((m) => m.id === messageRef.id)) {
                    const messageInstance = new Message(messageRef, messageData)
                    runInAction(() => {
                        this._messages.push(messageInstance)
                        this._lastMessage = messageInstance
                    })
                }
            })
        })

        this.setDisposer('messages', unsubscribe)
    }

    async sendMessage(text: string) {
        const organization = store.getMember?.getCurrentOrganization
        if (!organization) {
            store.getAlert.showAlert('No organization found for current user')
            throw new Error('No organization found for current user')
        }

        try {
            runInAction(() => (this._isSending = true))
            const res = await sendMessage({
                text,
                contentPath: this._ref.path,
                organizationId: organization.id,
            })
            this._logger.info('Response from sending message:', res)
            return res
        } catch (e) {
            this._logger.error('Error sending message:', e)
            throw e
        } finally {
            runInAction(() => (this._isSending = false))
        }
    }

    markAsRead = async (messageId: string) => {
        const message = this.messages.find((m) => m.id === messageId)
        if (!message) {
            logger.error('Message not found', messageId)
            return
        }

        if (message.participant.path === this.organization.path) {
            logger.warn('Message from the same organization', messageId)
            return
        }

        const messageToMark = this._messageToMarkAsRead
        if (messageToMark?.id === messageId) {
            logger.warn('Message to be marked as seen ID is the same as previous')
            return
        }

        const myLastSeen = this.lastSeen[escapeDocPath(this.organization.path)]
        const lastSeenDate = myLastSeen?.date
        if (!lastSeenDate) {
            logger.warn('No last seen date found', messageId)
            return
        }

        if (message.createdAt.getTime() < lastSeenDate.getTime()) {
            logger.warn('Message already seen or before last seen date', messageId)
            return
        }

        if (!messageToMark || message.createdAt > messageToMark.createdAt) {
            this._messageToMarkAsRead = message
            if (this._markAsReadTimeout) {
                clearTimeout(this._markAsReadTimeout)
            }
        }

        logger.info('calling markAsRead with debounce timeout', messageId)

        this._markAsReadTimeout = window.setTimeout(async () => {
            try {
                await markAsRead(messageId, this.organization.id, this._ref.id)
            } catch (e) {
                this._logger.error('Error marking message as read:', e)
                throw e
            }
        }, 500)
    }

    private setDisposer(key: DisposerKeys, disposer: Disposer) {
        this._disposers[key]?.()
        this._disposers[key] = disposer
    }

    destroy() {
        this.unsubscribe()
    }

    private unsubscribe() {
        this._logger.info('Unsubscribing from all listeners', this._ref.path)
        Object.values(this._disposers).forEach((disposer) => disposer?.())
        this._disposers = {}
    }
}
